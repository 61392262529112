import React from 'react'
import './Header.scss'
import {Link} from "react-router-dom";


interface LinkContainerProps {
    url: string,
    name: string,
    top: boolean,
    location?: any
}


class LinkGroup extends React.Component<LinkContainerProps, any> {

    state = {bsStyle: {width: '0x'}}


    onMouseEnter = () => {
        this.setState({bsStyle: {width: '80%'}})

    }
    onMouseLeave = () => {
        this.setState({bsStyle: {width: '0px'}})
    }


    render() {

        let bsStyle = {width: '0%'};
        try {
            if (window.location.pathname.indexOf(this.props.url) !== -1) {
                bsStyle.width = '80%'
            } else {
                bsStyle.width = this.state.bsStyle.width
            }
        } catch (e) {
            console.error(e)
        }

        let clss: any = 'link ' + (this.props.top ? '' : 'link-small')
        let btmClss: any = 'under-line ' + (this.props.top ? '' : 'under-line-small')

        return <div className='link-group' onMouseEnter={this.onMouseEnter} onMouseLeave={this.onMouseLeave}>
            <Link to={this.props.url} className={clss}>{this.props.name}</Link>
            <div style={bsStyle} className={btmClss}></div>

        </div>
    }
}

export default LinkGroup;