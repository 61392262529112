import React from 'react'
import './Header.scss'
import LinkGroup from "./LinkGroup";
import Logo from './Logo'
import {Link} from "react-router-dom";
import {ctx} from "./Ctx";


interface LinkContainerProps {
    url: string,
    name: string
}


class Header extends React.Component<any, any> {

    state = {
        top: true,
        containerStyle: {}
    }


    componentDidMount() {
        document.addEventListener('scroll', this.onDocumentScroll);

        setTimeout(() => {
            this.setState({
                top: true,
                containerStyle: {
                    backgroundColor: 'rgba(255,255,255,0)',
                    height: 80,
                }
            })

        }, 100);


    }

    componentWillUnmount() {
        document.addEventListener('scroll', this.onDocumentScroll);
    }

    onDocumentScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        let threshold: number = 100;


        if (scrollTop > threshold) {
            this.setState({
                top: false,
                containerStyle: {
                    backgroundColor: 'rgba(255,255,255,1)',
                    height: 40,
                    boxShadow: '0px 3px 5px rgba(0,0,0,0.3)'
                }
            })
        } else {
            this.setState({
                top: true,
                containerStyle: {
                    backgroundColor: 'rgba(255,255,255,0)',
                    height: 80,
                }
            })
        }


    }

    run = () => {
        console.log('in LinkGroup')
    }

    onLangChange = () => {
        this.props.onAppReload();
    }

    render() {

        return <div className='header' style={this.state.containerStyle}>
            <div className='container'>
                <div className='logo-container'>
                    <Link to='/index'>
                        <Logo top={this.state.top}/>
                    </Link>
                </div>
                <div className='link-container'>
                    <LinkGroup top={this.state.top} name={ctx.s('走进碳华导航')} url='/about'/>
                    <LinkGroup top={this.state.top} name={ctx.s('产品中心')} url='/product'/>
                    <LinkGroup top={this.state.top} name={ctx.s('科技创新与质量保证')} url='/tech'/>
                    <LinkGroup top={this.state.top} name={ctx.s('产品应用')} url='/solution'/>
                    <LinkGroup top={this.state.top} name={ctx.s('新闻动态')} url='/news'/>
                    <LinkGroup top={this.state.top} name={ctx.s('人力资源')} url='/hr'/>
                    <LinkGroup top={this.state.top} name={ctx.s('联系我们')} url='/contact'/>
                    <div className='lang-group' style={{
                        color: this.state.top ? '' : '#707071'
                    }} onClick={this.onLangChange}>
                        {ctx.lang === 'cn' ? '한글' : '中文'}
                    </div>

                </div>
            </div>
        </div>
    }
}

export default Header;