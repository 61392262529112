import React from 'react'
import {BrowserRouter as Router, Route, Link} from 'react-router-dom'
import '../common/Page.scss';
import './Index.scss'
import Wheel from "./Wheel";
import Hg1 from "./Hg1";
import Hg3Gsys from "./Hg3Gsys";
import Hg2 from "./Hg2";
import Hg4Rxxcl from "./Hg4Rxxcl"
import Hg4Big from "./Hg4Big";
import Hg5 from "./Hg5";
import HgRxxclBange from "./HgRxxclBange";
import MagicFontScroll from "../common/MagicFontScroll";
import {ctx} from "../common/Ctx";

class Index extends React.Component<any, any> {


    render() {

        return <div className='page'>
            <Wheel/>

            <div className='page-wrap page-wrap-color-white gsys' style={{height: 700, zIndex: 999}}>
                <div className='page-center'>
                    <Hg1 invokerY={500} top={130} left={80} height={100} width={30} pic={' '} logo={''}/>
                    <Hg1 invokerY={500} top={130} left={140} height={30} width={100} pic={' '} logo={' '}/>
                    <Hg2 invokerY={500} top={180} left={180} width={60} height={60} pic={' '} logo={' '}/>

                    <Hg3Gsys invokerY={500} text={ctx.s('公司优势')} lineHeight={290} fontSize={40} top={30} left={540}
                             width={300} height={300}
                             pic={''} logo={''}/>
                    <Hg2 invokerY={500} top={250} left={690} width={90} height={80} pic={''} logo={''}/>
                    <Hg1 invokerY={500} top={330} left={760} height={100} width={30} pic={''} logo={''}/>

                    <Hg4Big invokerY={400} top={230} left={900} width={800} height={400} pic={'img/index/2.jpg'}
                            logo={'img/index/s2.svg'}/>

                    <div className='jsll'>
                        <div className='title'>{ctx.s('技术力量雄厚')}</div>
                        <div className='content'>
                            {ctx.s('引进国家千人')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='page-wrap page-wrap-color-yellow tdsl' style={{height: 700}}>
                <div className='page-center'>
                    <Hg4Big invokerY={1100} rotate={10} top={100} left={-200} width={800} height={400}
                            pic={'img/index/1.jpg'} logo={'img/index/s1.svg'}/>
                    <div className='tdsl-text'>
                        <div className='title'>{ctx.s('团队实力雄厚')}</div>
                        <div className='content'>
                            <p>{ctx.s('上海宏鹰股权')}</p>
                            <p>{ctx.s('蚌埠经济开发区投资集团有限公司')}</p>
                            <p>{ctx.s('企业负责人具有数十年')}</p>
                        </div>
                    </div>

                </div>
            </div>

            <div className='page-wrap page-wrap-color-white cpdtx' style={{height: 1174, zIndex: 999}}>
                <div className='page-center'>

                    <Hg4Big invokerY={1800} rotate={10} top={-150} left={900} width={800} height={400}
                            pic={'img/index/3.jpg'} logo={'img/index/s3.svg'}/>


                    <Hg1 top={330} left={180} height={100} width={30} pic={' '} logo={''}/>
                    <Hg1 top={380} left={150} height={200} width={50} pic={' '} logo={''}/>
                    <Hg1 top={390} left={80} height={100} width={30} pic={' '} logo={''}/>

                    <Hg2 top={438} left={230} width={100} height={60} pic={' '} logo={' '}/>
                    <Hg1 top={588} left={285} height={100} width={30} pic={' '} logo={''}/>

                    <Hg1 top={590} left={100} height={30} width={100} pic={' '} logo={' '}/>
                    <Hg2 top={650} left={130} width={60} height={60} pic={' '} logo={' '}/>

                    <div className='cpdtx-text'>
                        <div className='title'>{ctx.s('产品的独特性')}</div>
                        <div className='content'>
                            {ctx.s('在实现超高导热特性下')}
                        </div>
                    </div>
                    <div className='cpdtx-card'>
                        <div className='left'>
                            <div
                                className='left-con-top'>{ctx.s('安徽碳华新材料科技有限公司于')}
                            </div>
                            <div
                                className='left-con-down'>{ctx.s('我们是一家致力于为客户')}
                            </div>
                            <button className='button'>{ctx.s('了解更多')}</button>
                        </div>

                        <div className='right'><img src='./img/index/4.jpg' alt=""/></div>
                    </div>

                    <Hg3Gsys text={ctx.s('走进碳华')} lineHeight={290} fontSize={40} top={610} left={533} width={300} height={300}
                             pic={''} logo={''}/>


                </div>
            </div>

            <div className='page-wrap  page-wrap-color-yellow index-wrap-column kjcx' style={{height: 1200}}>
                <div className='kjcx-img-text'>
                    <MagicFontScroll str={ctx.s('宗旨')} listenTop={2900} strStyle={{}} speed={0.1}/>


                </div>
                <div className='page-center'>
                    <div>
                        <Hg4Rxxcl text={ctx.s('热学新材料')} top={-200} left={500} width={400} height={400} pic={''} logo={''}/>
                        <Hg5 text={ctx.s('常规人工石墨高导膜')} clsNm='lan' top={200} left={600} width={200} height={210} pic={''} logo={''}/>
                        <Hg5  text={ctx.s('超薄超厚人工石墨高导膜')} clsNm='zi' top={200} left={300} width={200} height={210} pic={''} logo={''}/>
                        <Hg5 text={ctx.s('超宽人工石墨高导膜')} clsNm='lv' top={200} left={900} width={200} height={210} pic={''} logo={''}/>
                    </div>
                </div>
                <HgRxxclBange top={770} left={-220} width={300} height={300} pic={''} logo={''}/>
                <HgRxxclBange shadow={true} top={710} right={-350} width={500} height={500} pic={''} logo={''}/>
            </div>


        </div>
    }
}

export default Index;