import React from 'react'
import './MagicFont.scss'

interface MagicFontProps {
    fontStyle?: any
}

class MagicFont extends React.Component<MagicFontProps, any> {

    state = {str: '', ani: 'signleFontFrame '}
    disPlayStr = (str: string): any => {
        this.setState({str: str, ani: 'signleFontFrame '})

    }
    hideStr = (): any => {
        this.setState({str: this.state.str, ani: 'hideFontFrame '})
    }

    render() {

        if (!this.state.str) {
            return <></>;
        }

        let fontArray: any = this.state.str.split('');
        return <>
            {
                fontArray.map((s: any, idx: number): any => {

                    let style: any = {
                        animation: this.state.ani + (0.2 * idx) + 's',
                        animationFillMode: 'forwards'
                    }


                    return <span style={style} className='signleFont' key={idx}>{s}</span>;
                })
            }


        </>
    }
}

export default MagicFont;