import React from 'react'
import axios from 'axios';
import './edit.scss'
import {api} from "../common/api";


export default class Index extends React.Component {


  

    constructor(props) {
        super(props);
        this.state = {
            list: [],
            currentEdit: '',
            rawList: [],

        }
    }


    //获取数据
    componentDidMount() {
        this.reloadAll()
    }


    async reloadAll(conditions = {}) {
        await axios.get(`${api.prefix}fetch_all`, {})
            .then(res => {
                if (res.status === 200) {
                    this.setState({list: res.data, rawList: res.data})
                }
            })
    }


    onEdit = (id) => {
        this.setState({currentEdit: id})
    }

    onTaChange = (id, type, e) => {
        let row = this.state.list.find((row) => row.id === id)
        if (row) {
            if (type === 'cn') {
                row.cn = e.target.value
            } else if (type === 'kor') {
                row.kor = e.target.value
            }
        }
        this.setState({list: this.state.list})
    }

    onSubmitChange = (id) => {
        let row = this.state.list.find((row) => row.id === id)
        if (row) {
            axios.post(`${api.prefix}updateContent`, row).then((res) => {
                    console.log(res)
                    this.setState({currentEdit: ''})
                }
            ).catch((err) => {
                this.setState({currentEdit: ''})
            })

        }
    }


    render() {
        return (
            <table className='edit-table'>
                <thead>
                <tr>
                    <th style={{width: 150}}>
                        页面
                        <select value={this.state.filter} onChange={(evt) => {

                            if (evt.target.value) {
                                let list = this.state.rawList.filter(rl => rl.page === evt.target.value)
                                this.setState({list: list})
                            }


                        }}>
                            <option></option>
                            <option value='导航栏'>导航栏</option>
                            <option value='页脚'>页脚</option>
                            <option value='首页'>首页</option>
                            <option value='产品中心'>产品中心</option>
                            <option value='科技创新与质量保证'>科技创新与质量保证</option>
                            <option value='产品应用'>产品应用</option>
                            <option value='新闻动态'>新闻动态</option>
                            <option value='走进碳华'>走进碳华</option>
                            <option value='联系我们'>联系我们</option>
                            <option value='人力资源'>人力资源</option>
                        </select>
                    </th>
                    <th>内容(中/韩)</th>
                </tr>
                </thead>
                <tbody>
                {
                    this.state.list.map((row) => {
                        return <tr>
                            <td>{row.page}</td>
                            <td>

                                {
                                    this.state.currentEdit === row.id ?
                                        <div key={row.id}>
                                            <div className='content-div'>
                                                <textarea onChange={this.onTaChange.bind(this, row.id, 'cn')}
                                                          value={row.cn} rows='10' className='content-div-ta'/>
                                            </div>
                                            <div className='content-div'>
                                                <textarea onChange={this.onTaChange.bind(this, row.id, 'kor')}
                                                          value={row.kor} rows='10' className='content-div-ta'/>
                                            </div>

                                            <div className='content-div'>
                                                <button onClick={this.onSubmitChange.bind(this, row.id)}>确定</button>
                                            </div>
                                        </div>
                                        :
                                        <div key={row.id}>
                                            <div className='content-div'>{row.cn}</div>
                                            <div className='content-div'>{row.kor}</div>
                                            <div className='content-div'>
                                                <button onClick={this.onEdit.bind(this, row.id)}>编辑</button>
                                            </div>
                                        </div>


                                }
                            </td>

                        </tr>
                    })
                }


                </tbody>
            </table>
        )


    }


}
