import React from 'react'
import '../common/Page.scss';
import './Solution.scss'
import TopZone from "../common/TopZone";
import SoHgBig from "./SoHgBig";
import CyGroup from "./CyGroup";
import {ctx} from "../common/Ctx";


class Solution extends React.Component<any, any> {


    render() {

        return <div className='page'>
            <TopZone text={<div>
                <div>

                </div>
                <div>

                </div>
            </div>} bgImg='/solution/bg0.jpg' iconImg='/solution/icon1.svg'/>


            <div className='page-wrap  page-wrap-color-white so-dz-bg' style={{height: 750, zIndex: 999}}>
                <div className='page-center'>
                    <SoHgBig top={10} left={383} width={600} height={600}
                             pic={"/solution/bg1.jpg"}
                             logo={"/solution/icon2.svg"}
                             text=   {ctx.s('电子产品')}
                             invokerY={100}/>
                    <div className='so-dz-cy-container'>

                        <CyGroup pic={"/solution/cbg/cbg1.jpg"}
                                 text= {ctx.s('智能手机')}
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg12.jpg"}
                                 text= {ctx.s('智能穿戴')}
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg2.jpg"}
                                 text={ctx.s('智能平板')}
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg9.jpeg"}
                                 text={ctx.s('超级本')}
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg10.jpg"}
                                 text={ctx.s('智能电视')}
                                 invokerY={100}

                        />

                    </div>
                </div>
            </div>


            <div className='page-wrap  page-wrap-color-yellow so-gy-bg' style={{height: 750, zIndex: 999}}>
                <div className='page-center'>
                    <SoHgBig top={10} left={383} width={600} height={600}
                             pic={"/solution/bg2.jpg"}
                             logo={"/solution/icon3.svg"}
                             text={ctx.s('工业应用')}
                             invokerY={600}/>
                    <div className='so-dz-cy-container'>

                        <CyGroup pic={"/solution/cbg/cbg11.jpg"}
                                 text={ctx.s('IC及制备')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg8.jpg"}
                                 text={ctx.s('通讯基站')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg5.jpg"}
                                 text={ctx.s('工业机器人')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />


                    </div>
                </div>
            </div>

            <div className='page-wrap  page-wrap-color-white so-gy-my' style={{height: 750, zIndex: 999,  backgroundColor: '#595757'}}>
                <div className='page-center'>
                    <SoHgBig top={10} left={383} width={600} height={600}
                             pic={"/solution/bg3.jpg"}
                             logo={"/solution/icon4.svg"}
                             text={ctx.s('民用产品')}
                             invokerY={1400}/>
                    <div className='so-dz-cy-container'>

                        <CyGroup pic={"/solution/cbg/cbg11.jpg"}
                                 text={ctx.s('航空航天')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg8.jpg"}
                                 text={ctx.s('检测仪器')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg5.jpg"}
                                 text={ctx.s('汽车')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg8.jpg"}
                                 text={ctx.s('LED照明')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />
                        <CyGroup pic={"/solution/cbg/cbg5.jpg"}
                                 text={ctx.s('电池')}
                                 color='#eeeeee'
                                 invokerY={100}

                        />

                    </div>
                </div>
            </div>


        </div>
    }
}

export default Solution;