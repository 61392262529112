import React from 'react'
import './MagicFont.scss'

interface MagicFontScrollProps {
    str: string
    listenTop: number
    strStyle: {}
    speed: number

}

class MagicFontScroll extends React.Component<MagicFontScrollProps, any> {


    state = {show: false}

    componentDidMount(): void {

        document.addEventListener('scroll', this.onScroll)

    }


    onScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (this.props.listenTop && scrollTop > this.props.listenTop) {
            this.setState({show: true})
        }

    }


    render() {

        if (!this.state.show || !this.props.str) {
            return <></>;
        }

        let fontArray: any = this.props.str.split('');
        return <>
            {
                fontArray.map((s: any, idx: number): any => {

                    let style: any = {
                        animation: 'signleFontFrame ' + (this.props.speed * idx) + 's',
                        animationFillMode: 'forwards',
                    }



                    return <span style={style} className='signleFont' key={idx}>{s}</span>;
                })
            }


        </>
    }
}

export default MagicFontScroll;