import React from 'react'
import {uuid} from "../common/UUID";
import './Solution.scss'
import AnimateUtil from "../common/AnimateUtil";
import {hexagonManager} from "../common/HexagonManager";
import SequenceAnimateGroup from "../animate/SequenceAnimateGroup";
import Animate from "../animate/Animate";

interface SoHgBigProps {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
}

class SoHgBig extends React.Component<SoHgBigProps, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    clipId0: string = uuid.create();
    filterId: string = uuid.create();
    pathFilterId: string = uuid.create();
    containerId: string = uuid.create();

    r = 25;
    logoHeight: number = 100;
    textId: string = uuid.create();
    iconId: string = uuid.create();

    invokered: boolean = false;

    componentDidMount() {

        document.addEventListener('scroll', this.invoke);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.invoke)
    }


    invoke = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;

        if (!this.invokered && this.props.invokerY && scrollTop > this.props.invokerY) {
            this.invokered = true;
            let group: SequenceAnimateGroup = new SequenceAnimateGroup();
            group.addAnimate(new Animate(300, this.step1));
            group.addAnimate(new Animate(500, this.step2));
            group.addAnimate(new Animate(500, this.step3));
            group.start();
        }
    }


    step1 = (pst: number) => {
        hexagonManager.draw(this.p0Id, this.props.height / 2 * pst * 0.8, this.props.height / 2, this.props.height / 2, this.r);

    }
    step2 = (pst: number) => {
        hexagonManager.draw(this.p1Id, this.props.height / 2 * pst * 0.7, this.props.height / 2, this.props.height / 2, this.r);
    }

    step3 = (pst: number) => {
        let iconY: number = (this.props.height - this.logoHeight) / 2 - this.logoHeight / 2;
        let textY: number = (this.props.height - this.logoHeight) / 2 + this.logoHeight / 2;
        // @ts-ignore
        document.getElementById(this.iconId).setAttribute('y', iconY - 300 * (1 - pst))

        // @ts-ignore
      //  document.getElementById(this.textId).setAttribute('y', textY )

    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',
            zIndex: 99

        }


        return <div id={this.containerId} style={frameStyle} className='so-hg-big'>

            <svg viewBox={`0 0 ${this.props.height} ${this.props.height}`} className='so-hg-svg'>
                <defs>
                    <filter id={this.pathFilterId} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceGraphic" dx="3" dy="3"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>
                    <mask id={this.clipId0} x="0" y="0" width="200%" height="200%">
                        <path filter={`url(#${this.pathFilterId})`} fill='#ffffff' id={this.p0Id} d=""/>
                    </mask>
                </defs>


                <image x='-10%' y='-10%'
                       filter={`url(#${this.filterId})`}
                       height="120%" width="120%"
                       mask={`url(#${this.clipId0})`}
                       xlinkHref={this.props.pic}/>
                <image id={this.iconId}
                       x={(this.props.height - this.logoHeight) / 2}
                       y={-800}
                       height={this.logoHeight}
                       width={this.logoHeight}
                       xlinkHref={this.props.logo}/>


                <foreignObject id={this.textId}
                               x={(this.props.height - 200) / 2}
                               y={ (this.props.height - this.logoHeight) / 2 + this.logoHeight / 2}
                               height='80'
                               width='200'
                >
                    <body >
                    <div className='so-text-block'>{this.props.text}</div>
                    </body>
                </foreignObject>


                <path fill='none' strokeWidth='1' stroke='#FFFFFF' id={this.p1Id} d=""/>


            </svg>

        </div>
    }
}

export default SoHgBig;