import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import axios from "axios";
import {ctx} from "./common/Ctx";
import {api} from './common/api';

axios.get(`${api.prefix}fetch_all`, {})
    .then(res => {
        if (res.status === 200) {
            ctx.list = res.data;
            ReactDOM.render(
                <React.StrictMode>
                    <App/>
                </React.StrictMode>,
                document.getElementById('root')
            );
        }
    })


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
