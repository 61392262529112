import React from 'react'
import Hexagon from "../common/Hexagon";
import {uuid} from "../common/UUID";
import './Hg3.scss'
import AnimateUtil from "../common/AnimateUtil";

class Hg4Big extends Hexagon {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();
    clipId0: string = uuid.create();
    filterId: string = uuid.create();
    pathFilterId:string=uuid.create();
    containerId: string = uuid.create();
    iconId: string = uuid.create();
    r = 25;

    imgHeight: number = 436;
    imgWidth: number = 640;
    invokered: boolean = false;

    componentDidMount() {
        document.addEventListener('scroll', this.invoke)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.invoke)
    }


    invoke = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (!this.invokered && this.props.invokerY && scrollTop > this.props.invokerY) {
            this.invokered = true;
            new AnimateUtil(500, this.step1, () => {
                new AnimateUtil(300, this.step2, () => {
                    new AnimateUtil(1000, this.step3, undefined).start();
                }).start();
            }).start();
        }
    }


    buildExpland = (pst: any) => {
        let exp = [
            {x: 80 * pst, y: 0},
            {x: 80 * pst, y: 0},
            {x: -80 * pst, y: 0},
            {x: -80 * pst, y: 0},
            {x: -80 * pst, y: 0},
            {x: 80 * pst, y: 0},

        ]
        return exp;
    }

    step1 = (pst: number) => {
        let height = this.imgHeight / 2 * pst * 0.8;
        this.redrawHexagon(this.p0Id, height, this.imgWidth / 2, this.imgHeight / 2, this.r * pst);
        this.redrawHexagon(this.p1Id, height, this.imgWidth / 2, this.imgHeight / 2 + 10, this.r * pst);

    }
    step2 = (pst: number) => {
        let height = this.imgHeight / 2 * 0.8;
        this.redrawHexagon(this.p0Id, height, this.imgWidth / 2, this.imgHeight / 2, this.r, this.buildExpland(pst));
        this.redrawHexagon(this.p1Id, height, this.imgWidth / 2, this.imgHeight / 2, this.r, this.buildExpland(pst));
    }

    step3 = (pst: number) => {
        let pol: any = document.getElementById(this.p0Id);
        let pol1: any = document.getElementById(this.p1Id);
        let height = this.imgHeight / 2 * 0.8;
        let rotate: any = (this.props.rotate ? this.props.rotate : -10) * pst;

        this.redrawHexagon(this.p1Id, height, this.imgWidth / 2 - 20 * pst, this.imgHeight / 2 + 20 * pst, this.r, this.buildExpland(1));
        pol.setAttribute('transform', `rotate(${rotate},${this.imgWidth / 2} ${this.imgHeight / 2})`)
        pol1.setAttribute('transform', `rotate(${rotate},${this.imgWidth / 2} ${this.imgHeight / 2})`)

    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',
            zIndex: 99

        }


        return <div id={this.containerId} style={frameStyle}>
            <svg viewBox={`0 0 ${this.imgWidth} ${this.imgHeight}`}>
                <defs>
                    <filter id={this.filterId} x="0" y="0" width="100%" height="100%">
                        <feColorMatrix type="matrix" values="
                        1 0 0 0 0
                        0 1 0 0 0
                        0 0 1 0 0
                        0 0 0 1 0 "/>
                    </filter>

                    <filter id={this.pathFilterId} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceGraphic" dx="6" dy="6"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>

                    <mask id={this.clipId0} x="0" y="0" width="200%" height="200%">
                        <path filter={`url(#${this.pathFilterId})`}  fill='#ffffff' id={this.p0Id} d=""/>
                    </mask>
                </defs>

                <path fill='none' strokeWidth='1' stroke='#F6DD64' id={this.p1Id} d=""/>
                <image x='-10%' y='-10%'
                       filter={`url(#${this.filterId})`}
                       height="120%" width="120%"
                       mask={`url(#${this.clipId0})`}
                       xlinkHref={this.props.pic}/>
                <image id={this.iconId}
                       filter='url(#f1)' x='120' y='160'
                       height="120" width="120"
                       xlinkHref={this.props.logo}/>

            </svg>

        </div>
    }
}

export default Hg4Big;