import React from 'react';
import Index from './index/Index';
import About from './about/About';
import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom'
import './App.scss'
import Header from "./common/Header";
import Footer from "./common/Footer";
import Edit from '../src/edit/Edit'
import Product from "./product/Product";
import Tech from "./tech/Tech";
import Solution from "./solution/Solution";
import News from "./news/News";
import Hr from "./hr/Hr";
import Contact from "./contact/Contact";
import {ctx} from "./common/Ctx";
import EditNews from "./edit/EditNews";
import EditImage from "./edit/EditImage";
import Admin from "./edit/Admin";

function Wrap(Page: any, onAppReload: any): any {

    return function () {

        let header: any = React.createRef();
        let footer: any = React.createRef();
        return <>
            <Header onAppReload={onAppReload} ref={header}/>
            <Page

                common={{
                    header: header,
                    footer: footer

                }}/>
            <Footer ref={footer}/>
        </>
    }
}

class App extends React.Component<any, any> {

    onAppReload = () => {
        if (ctx.lang === 'cn') {
            ctx.lang = 'kor'
        } else if (ctx.lang === 'kor') {
            ctx.lang = 'cn'
        }
        this.setState({})
    }


    render() {
        return (
            <div>
                <Router>


                    <Route path="/about" component={Wrap(About, this.onAppReload)}/>
                    <Route path="/product" component={Wrap(Product, this.onAppReload)}/>
                    <Route path="/tech" component={Wrap(Tech, this.onAppReload)}/>

                    <Route path="/solution" component={Wrap(Solution, this.onAppReload)}/>
                    <Route path="/news" component={Wrap(News, this.onAppReload)}/>
                    <Route path="/hr" component={Wrap(Hr, this.onAppReload)}/>
                    <Route path="/contact" component={Wrap(Contact, this.onAppReload)}/>

                    <Route path="/edit" exact={true} component={Edit}/>

                    <Route path="/edit_news" exact={true} component={EditNews}/>

                    <Route path="/edit_image" exact={true} component={EditImage}/>
                    <Route path="/admin" exact={true} component={Admin}/>
                    <Route path="/index" exact={true} component={Wrap(Index, this.onAppReload)}/>
                    <Redirect path="/" to="/index"/>
                </Router>

            </div>
        );
    }
}

export default App;
