import React from 'react'
import {uuid} from "../common/UUID";
import Animate from "../animate/Animate";
import {hexagonManager} from "../common/HexagonManager";

interface AboutMubiaoItemProps {
    bg: string
    icon: string
    text: string
    title:string
}

class AboutMubiaoItem extends React.Component<AboutMubiaoItemProps, any> {

    svgId: string = uuid.create();
    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    componentDidMount() {
       this.invoke()
    }

    componentWillUnmount() {
      //  document.removeEventListener('scroll', this.invoke)
    }

    invoke = () => {
        new Animate(1000, (pst: number) => {
            hexagonManager.draw(this.p0Id, 180 * pst, 200, 200);
        }).start();
    }

    render() {


        return <div className='about-mubiao-item' style={{backgroundImage: `url(${this.props.bg})`}}>
            <div className='about-mubiao-item-pic'>
                <svg id={this.svgId} width='100%' height='250' version="1.1" viewBox="0 0 400 400">
                    <path fill='none' stroke='#eeeeee' strokeWidth='1.5' id={this.p0Id}/>
                </svg>
                <div className='about-mubiao-item-icon' style={{backgroundImage:`url(${this.props.icon})`}}>

                </div>

                <div className='about-mubiao-item-title'>
                    {this.props.title}
                </div>


            </div>
            <div className='about-mubiao-item-text'>
                {this.props.text}
            </div>

        </div>
    }
}

export default AboutMubiaoItem;