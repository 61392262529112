import * as React from 'react';
import './Logo.scss'


interface LogoProps {
    top: boolean
}

class Logo extends React.Component<LogoProps, any> {

    render() {

        let style: React.CSSProperties = {
            transform: 'scale(0.5)'
        };
        let topCls = '';
        if (this.props.top) {
            style.transform = 'scale(1)'
            topCls = 'cls-white';
        } else {
            style.transform = 'scale(0.6)'
            topCls = '';
        }


        return <div style={style} className='logo'>
            <svg id="图层_1" data-name="图层 1" viewBox="0 0 487.38 131.62">
                <defs>
                    <clipPath id="clip-path">
                        <rect className="cls-1" x="-1978.05" y="-890.64" width="1387.72" height="908.85"/>
                    </clipPath>
                    <clipPath id="clip-path-2">
                        <rect className="cls-1" x="-2006.29" y="-891.15" width="1620.56" height="911.56"/>
                    </clipPath>
                </defs>
                <title>切图-logo</title>
                <polygon className={'cls-2 ' + topCls}
                         points="99.07 14.57 57.12 14.56 57.13 14.54 39.92 14.55 39.91 14.56 39.9 14.56 31.29 29.48 31.31 29.48 15.46 56.96 32.68 56.97 48.52 29.48 62.08 29.48 62.08 56.97 76.98 56.97 76.98 29.48 90.47 29.47 93.54 34.73 102.15 19.83 99.07 14.57"/>
                <polygon className={'cls-3 ' + topCls}
                         points="57.12 117.07 88.88 117.06 80.28 102.16 48.53 102.16 27.58 65.8 30.6 60.51 13.39 60.49 10.37 65.78 31.32 102.16 31.32 102.16 37.46 112.82 39.9 117.06 39.9 117.06 39.91 117.08 54.23 117.07 57.12 117.08 57.12 117.07"/>
                <polygon className={'cls-4 ' + topCls}
                         points="128.68 65.81 104.21 23.41 95.59 38.31 111.46 65.8 90.48 102.13 84.38 102.17 92.98 117.08 99.08 117.04 120.07 80.71 120.07 80.72 128.68 65.81"/>
                <polygon className={'cls-3 ' + topCls}
                         points="44.53 66.23 35.21 66.23 35.59 67.08 38.66 67.08 38.66 76.18 41.09 76.18 41.09 67.08 44.53 67.08 44.53 66.23"/>
                <path className={'cls-3 ' + topCls}
                      d="M48.94,66,44.1,76.18h1.65l1.31-2.72h3.86l1.25,2.72h2.49L50,66Zm-1.42,6.51L49,69.33l1.47,3.21Z"/>
                <polygon className={'cls-3 ' + topCls}
                         points="63.42 72.56 57.75 66.23 56.19 66.23 56.19 76.18 57.73 76.18 57.73 69.34 63.9 76.32 64.94 76.32 64.94 67.01 63.42 66.23 63.42 72.56"/>
                <polygon className={'cls-3 ' + topCls}
                         points="74.01 70.59 70.01 70.59 70.01 66.23 67.57 66.23 67.57 76.18 70.01 76.18 70.01 71.44 74.01 71.44 74.01 76.18 76.43 76.18 76.43 66.23 74.01 66.23 74.01 70.59"/>
                <path className={'cls-3 ' + topCls}
                      d="M86.6,72.63a3.22,3.22,0,0,1-.18,1.08,2.75,2.75,0,0,1-.51.88,2.51,2.51,0,0,1-.8.6,2.56,2.56,0,0,1-1,.22A2.62,2.62,0,0,1,83,75.19a2.51,2.51,0,0,1-.8-.6,2.6,2.6,0,0,1-.52-.88,3.22,3.22,0,0,1-.18-1.08v-6.4H79.05v6.38a3.8,3.8,0,0,0,.29,1.46,3.5,3.5,0,0,0,.85,1.21,4.23,4.23,0,0,0,1.41.82,5.71,5.71,0,0,0,1.93.3,6.48,6.48,0,0,0,2.1-.3,3.9,3.9,0,0,0,1.43-.82,3.18,3.18,0,0,0,.82-1.2,4.1,4.1,0,0,0,.26-1.45v-6.4H86.6Z"/>
                <path className={'cls-3 ' + topCls}
                      d="M95.35,66h-1L89.45,76.18h1.66l1.3-2.72h3.87l1.24,2.72H100Zm-2.48,6.51,1.52-3.21,1.47,3.21Z"/>
                <polygon className={'cls-3 ' + topCls}
                         points="393.31 103.7 400.28 103.7 401.24 52.66 394.18 52.66 393.31 103.7"/>
                <rect className={'cls-3 ' + topCls} x="419.11" y="52.66" width="7.15" height="51.04"/>
                <polygon className={'cls-3 ' + topCls}
                         points="415.06 27.95 404.95 27.95 404.95 42.59 393.31 42.59 393.31 47.56 404.95 47.56 404.95 103.7 415.06 103.7 415.06 47.56 426.7 47.56 426.7 42.59 415.06 42.59 415.06 27.95"/>
                <polygon className={'cls-3 ' + topCls}
                         points="438.14 52.66 433.99 103.7 444.1 103.7 448.24 52.66 438.14 52.66"/>
                <polygon className={'cls-3 ' + topCls}
                         points="466.64 42.59 466.64 33.79 456.51 27.95 456.51 42.59 430.83 42.59 430.83 47.56 456.51 47.56 456.51 99.08 446.7 99.08 446.7 103.7 466.64 103.7 466.64 47.56 470.67 47.56 470.67 42.59 466.64 42.59"/>
                <polygon className={'cls-3 ' + topCls}
                         points="354.11 93.47 351.57 103.7 361.68 103.7 363.94 93.47 363.94 55.82 372.41 55.82 372.41 103.7 382.52 103.7 382.52 55.82 386.49 55.82 386.49 50.85 363.94 50.85 363.94 35.52 386.49 32.92 386.49 27.95 354.11 31.59 354.11 93.47"/>
                <polygon className={'cls-3 ' + topCls}
                         points="309.13 103.2 316.58 103.2 318.87 76.15 311.68 76.15 309.13 103.2"/>
                <polygon className={'cls-3 ' + topCls}
                         points="339.17 76.15 341.27 103.2 348.71 103.2 346.36 76.15 339.17 76.15"/>
                <polygon className={'cls-3 ' + topCls}
                         points="344.79 45.18 334.46 45.18 332.55 56.51 325.13 56.51 323.39 45.18 312.98 45.18 314.8 56.51 309.13 56.51 309.13 61.48 323.87 61.48 323.87 67.02 309.13 67.02 309.13 71.99 323.87 71.99 323.87 99.15 320.83 99.15 320.83 103.7 323.87 103.7 333.98 103.7 333.98 71.99 348.71 71.99 348.71 67.02 333.98 67.02 333.98 61.48 348.71 61.48 348.71 56.51 342.96 56.51 344.79 45.18"/>
                <polygon className={'cls-3 ' + topCls}
                         points="348.63 36.46 334.06 36.46 332.63 27.92 322.26 27.92 323.7 36.46 309.13 36.46 309.13 41.43 348.63 41.43 348.63 36.46"/>
                <path className={'cls-3 ' + topCls}
                      d="M145,38.18h10.17L143,65.45h2.61l-.95,38.25H174v-44H157.46l8.83-21.53h8.53v-5H142.34Zm11.21,26.51h7.78v34h-7.78Z"/>
                <polygon className={'cls-3 ' + topCls}
                         points="218.15 39.04 208.11 33.31 208.11 46.39 204.36 46.39 204.36 28.07 194.35 28.07 194.35 46.39 190.27 46.39 190.27 33.31 180.26 33.31 180.26 51.39 218.15 51.39 218.15 39.04"/>
                <polygon className={'cls-3 ' + topCls}
                         points="210.02 67.03 201.23 67.03 201.23 81.05 191.24 103.7 200.59 103.7 205.95 90.45 212.35 103.7 220.66 103.7 210.02 81.05 210.02 67.03"/>
                <polygon className={'cls-3 ' + topCls}
                         points="215.95 82.32 217.5 68.78 213.26 68.78 211.79 82.32 215.95 82.32"/>
                <polygon className={'cls-3 ' + topCls}
                         points="194.24 82.32 198.49 82.32 196.94 68.78 192.77 68.78 194.24 82.32"/>
                <polygon className={'cls-3 ' + topCls}
                         points="219.33 64.69 219.33 59.72 192.11 59.72 192.55 54.16 183.1 54.16 182.66 59.72 177.59 59.72 177.59 64.69 182.26 64.69 178.95 103.7 188.4 103.7 191.71 64.69 219.33 64.69"/>
                <polygon className={'cls-3 ' + topCls}
                         points="269 72.82 258.9 72.82 258.9 80.67 225.27 80.67 225.27 85.64 258.9 85.64 258.9 103.7 269 103.7 269 85.64 302.63 85.64 302.63 80.67 269 80.67 269 72.82"/>
                <polygon className={'cls-3 ' + topCls}
                         points="239.63 72 249.72 72 249.72 43.97 248.21 43.97 253.39 27.95 241.54 27.95 228 60.18 239.63 60.18 239.63 72"/>
                <polygon className={'cls-3 ' + topCls}
                         points="266.84 70.95 301.21 70.95 301.21 65.98 276.91 65.98 276.91 52.58 301.21 50.49 301.21 45.52 276.91 47.62 276.91 33.79 266.84 27.95 266.84 48.63 254.56 49.59 254.56 54.56 266.84 53.59 266.84 70.95"/>
            </svg>
        </div>
    }
}


export default Logo;