import React from 'react'
import '../common/Page.scss';
import './Product.scss'
import Hg4Rxxcl from "../index/Hg4Rxxcl";
import Hg5 from "../index/Hg5";
import TopZone from "../common/TopZone";
import {ctx} from "../common/Ctx";
import HgProChanpin from "./HgProChanpin";

class Product extends React.Component<any, any> {

    state = {currentDetail: undefined}


    changGui = (): any => {
        return <>
            <div className='page-wrap page-wrap-color-white '
                 style={{height: 450, zIndex: 1, backgroundColor: '#6f83cc'}}>
                <div className='page-center'>

                    <div className='pro-text'>
                        <div className='pro-text-tilte'>{ctx.s('常规人工石墨高导膜')}</div>
                        <div className='pro-text-tilte2'>{ctx.s('常规宽幅人工石墨散热膜：')}</div>
                        <div className='pro-text-content'>{ctx.s('17um、25um、32um、40um')}</div>
                        <div
                            className='pro-text-content'>{ctx.s('同时本公司还研制出25um的超导热率膜')}
                        </div>
                    </div>
                </div>
                <HgProChanpin top={170} left={-220} width={300} height={300} pic={''} logo={''}/>
                <HgProChanpin top={0} shadow={true} right={-350} width={500} height={500} pic={''} logo={''}/>
            </div>
            <div className='page-wrap wangge' style={{height: 600, zIndex: 1}}>
                <div className='page-center'>
                    <div className='p-container'>

                        <div className='p-item'>
                            <div className='p-item-pic ' style={{backgroundImage: 'url(/product/conew_17um.jpg)'}}></div>
                            <div className='p-item-title'>17um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_25um.jpg)'}}></div>
                            <div className='p-item-title'>25um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_32um.jpg)'}}></div>
                            <div className='p-item-title'>32um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_40um.jpg)'}}></div>
                            <div className='p-item-title'>40um</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }

    chaoBao = (): any => {
        return <>
            <div className='page-wrap page-wrap-color-white '
                 style={{height: 850, zIndex: 1, backgroundColor: '#503c90'}}>
                <div className='page-center'>

                    <div className='pro-text'>
                        <div className='pro-text-tilte'>{ctx.s('超薄超厚人工石墨高导膜')}</div>
                        <div style={{color:'#6f83cc'}} className='pro-text-tilte2'>
                            {ctx.s('10um超薄人工石墨散热膜：')}
                        </div>
                        <div className='pro-text-content'>
                            {ctx.s('10um超薄人工石墨散热膜')}
                        </div>

                        <div style={{color:'#6f83cc'}} className='pro-text-tilte2'>
                            {ctx.s('70um厚人工石墨散热膜：')}
                        </div>
                        <div className='pro-text-content'>
                            {ctx.s('70um超薄人工石墨散热膜')}
                        </div>

                        <div style={{color:'#6f83cc'}} className='pro-text-tilte2'>
                            {ctx.s('100um超厚人工石墨散热膜：')}
                        </div>
                        <div className='pro-text-content'>
                            {ctx.s('100um超厚人工石墨散热膜')}
                        </div>

                        <div style={{color:'#6f83cc'}} className='pro-text-tilte2'>
                            {ctx.s('120um特殊厚度人工石墨散热膜：')}
                        </div>
                        <div className='pro-text-content'>
                            {ctx.s('120um特殊厚度人工石墨散热膜')}
                        </div>

                    </div>
                </div>
                <HgProChanpin top={170} left={-220} width={300} height={300} pic={''} logo={''}/>
                <HgProChanpin top={0} shadow={true} right={-350} width={500} height={500} pic={''} logo={''}/>
            </div>
            <div className='page-wrap wangge' style={{height: 600, zIndex: 1}}>
                <div className='page-center'>
                    <div className='p-container'>

                        <div className='p-item'>
                            <div className='p-item-pic ' style={{backgroundImage: 'url(/product/conew_10um.jpg)'}}></div>
                            <div className='p-item-title'>10um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_70um.jpg)'}}></div>
                            <div className='p-item-title'>70um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_100um.jpg)'}}></div>
                            <div className='p-item-title'>100um</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/conew_120um.jpg)'}}></div>
                            <div className='p-item-title'>120um</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


    chaoKuan = (): any => {
        return <>
            <div className='page-wrap page-wrap-color-white '
                 style={{height: 750, zIndex: 1, backgroundColor: '#8abb33'}}>
                <div className='page-center'>

                    <div className='pro-text'>
                        <div className='pro-text-tilte'>{ctx.s('超宽人工石墨高导膜')}</div>

                        <div className='pro-text-tilte2'>{ctx.s('200-430mm宽幅人工石墨散热膜：')}</div>
                        <div className='pro-text-content'>{ctx.s('200-430mm宽的人工石墨散热膜')}</div>

                        <div className='pro-text-tilte2'>{ctx.s('430-600mm宽幅人工石墨散热膜：')}</div>
                        <div className='pro-text-content'>{ctx.s('430-600mm宽幅人工石墨膜')}</div>

                        <div className='pro-text-tilte2'>{ctx.s('600-800mm宽幅人工石墨散热膜：')}</div>
                        <div className='pro-text-content'>{ctx.s('600-800mm宽幅人工石墨散热膜')}</div>

                    </div>
                </div>
                <HgProChanpin top={170} left={-220} width={300} height={300} pic={''} logo={''}/>
                <HgProChanpin top={0} shadow={true} right={-350} width={500} height={500} pic={''} logo={''}/>
            </div>
            <div className='page-wrap wangge' style={{height: 600, zIndex: 1}}>
                <div className='page-center'>
                    <div className='p-container' style={{width:800}}>

                        <div className='p-item'>
                            <div className='p-item-pic ' style={{backgroundImage: 'url(/product/200-430mm宽幅.jpg)'}}></div>
                            <div className='p-item-title'>200-430mm</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/430-600mm宽幅.jpg)'}}></div>
                            <div className='p-item-title'>430-600mm</div>
                        </div>
                        <div className='p-item'>
                            <div className='p-item-pic' style={{backgroundImage: 'url(/product/600-800mm宽幅.jpg)'}}></div>
                            <div className='p-item-title'>600-800mm</div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    }


    detail = (d: any) => {
        this.setState({currentDetail: d})
    }

    getDetail = () => {
        if (this.state.currentDetail === 'changgui') {
            return this.changGui()
        } else if (this.state.currentDetail === 'chaobao') {
            return this.chaoBao()
        } else if (this.state.currentDetail === 'chaokuan') {
            return this.chaoKuan()
        } else {
            return this.changGui()
        }
    }

    render() {
        return <div className='page'>
            <TopZone bgImg='/product/top.jpg' iconImg='/product/top-icon-svg.svg'/>
            <div className='page-wrap  page-wrap-color-white ' style={{height: 600, zIndex: 999}}>
                <div className='page-center'>
                    <div>
                        <Hg4Rxxcl text={ctx.s('热学新材料')} top={-100} left={500} width={400} height={400} pic={''}
                                  logo={''}/>
                        <Hg5 onClick={this.detail.bind(this, 'changgui')} text={ctx.s('常规人工石墨高导膜')} clsNm='lan'
                             top={300}
                             left={300} width={200} height={210} pic={''} logo={''}/>
                        <Hg5 onClick={this.detail.bind(this, 'chaobao')} text={ctx.s('超薄超厚人工石墨高导膜')} clsNm='zi'
                             top={300}
                             left={600} width={200} height={210} pic={''} logo={''}/>
                        <Hg5 onClick={this.detail.bind(this, 'chaokuan')} text={ctx.s('超宽人工石墨高导膜')} clsNm='lv' top={300}
                             left={900}
                             width={200} height={210} pic={''} logo={''}/>
                    </div>
                </div>
            </div>


            {
                this.getDetail()
            }


        </div>
    }
}

export default Product;