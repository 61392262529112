class UUID {
    create() {
        return (this.S4() + "_" + this.S4() + "_" + this.S4() + "_" + this.S4() + "_" + this.S4() + "_" + this.S4());
    }

    S4() {
        return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
    }
}

let uuid:UUID = new UUID();


export {uuid};