import React from 'react'
import '../common/Page.scss';
import './News.scss'
import TopZone from "../common/TopZone";
import NewsPanelItem from "./NewsPanelItem";
import {ctx} from "../common/Ctx";
import axios from "axios";
import { api } from '../common/api';

class News extends React.Component<any, any> {

    state = {
        list: [],
        display: 'list',
        news: {sections: [{cn: '', kor: ''}], images: []}
    }

    componentDidMount() {
        this.initPage()


    }

    initPage = () => {
        axios.get(`${api.prefix}list_news`, {})
            .then(res => {
                if (res.status === 200) {
                    this.setState({
                        list: res.data
                    })

                }
            })
    }

    getImageUrl = (n: any) => {
        if (n && n.images && n.images.length > 0) {
            return `${api.prefix}news_img?imgId=${n.images[0].id}`
        }
        return ''
    }


    showDetail = (news: any) => {
        this.setState({
            display: 'detail',
            news: news
        })
    }

    showList = () => {
        this.setState({
            display: 'list'
        })
    }


    render() {

        return <div className='page'>
            <TopZone text={<div>
                <div></div>
                <div></div>
            </div>} bgImg='/news/bg1.jpg' iconImg='/news/icon1.svg'/>

            {this.state.display === 'detail' ?
                <div>
                    <div className='page-wrap  page-wrap-color-white new-kt-bg' style={{height: 1400, zIndex: 999}}>
                        <div className='page-center'>

                            <div className='new-detail-container'>
                                <div className='new-detail-title'>
                                    {
                                        this.state.news && this.state.news.sections && this.state.news.sections.length > 1 && ctx.lang === 'cn' ?
                                            this.state.news.sections[1].cn
                                            :
                                            this.state.news.sections[1].kor
                                    }

                                </div>
                                {
                                    this.state.news && this.state.news.sections && this.state.news.sections.length > 2 ?
                                        <>
                                            {
                                                this.state.news.sections.map((sec: any, idx: number) => {
                                                    if (idx > 1) {
                                                        return <div className='news-detail-sec'>
                                                            {
                                                                ctx.lang === 'cn' ?
                                                                    sec.cn
                                                                    :
                                                                    sec.kor
                                                            }
                                                        </div>


                                                    }
                                                    return undefined;
                                                })
                                            }

                                        </>
                                        :
                                        undefined
                                }
                                <div className='news-detail-image-container'>
                                    {
                                        this.state.news && this.state.news.images && this.state.news.images.map((img: any) => {
                                            return <div className='news-detail-image-item'>
                                                <img className='news-detail-image' src={`${api.prefix}news_img?imgId=${img.id}`}/>
                                            </div>
                                        })
                                    }
                                </div>
                                <button onClick={this.showList}>返回列表</button>
                            </div>
                        </div>
                    </div>
                </div>
                :
                <div>
                    <div className='page-wrap  page-wrap-color-white new-kt-bg' style={{height: 600, zIndex: 999}}>
                        <div className='page-center'>
                            <div className='news-panel-container'>
                                <NewsPanelItem bg={'/news/bg2.jpg'} icon={'/about/item-icon1.svg'}
                                               title={ctx.s('行业动态')}
                                />
                                <NewsPanelItem bg={'/news/bg4.jpg'} icon={'/about/item-icon2.svg'}
                                               title={ctx.s('公司新闻')}
                                />
                                <NewsPanelItem bg={'/news/bg5.jpg'} icon={'/about/item-icon3.svg'}
                                               title={ctx.s('专题报道')}
                                />
                            </div>
                        </div>
                    </div>
                    {
                        this.state.list && this.state.list.map((n: any) => {
                            return <div className='page-wrap  page-wrap-color-white '
                                        style={{height: 300, zIndex: 999}}>
                                <div className='page-center'>
                                    <div className='news-panel-container' onClick={this.showDetail.bind(this, n)}>
                                        <div className='news-card'>
                                            <div className='lanmu'>
                                                {
                                                    n.sections && n.sections.length > 1 && ctx.lang === 'cn' ?
                                                        n.sections[0].cn
                                                        :
                                                        n.sections[0].kor
                                                }
                                            </div>
                                            <div className='n-content'>
                                                <div className='c-left'>
                                                    <div className='c-left-top'>
                                                <span className='c-left-top-span'>
                                                  {
                                                      n.sections && n.sections.length > 2 && ctx.lang === 'cn' ?
                                                          n.sections[1].cn
                                                          :
                                                          n.sections[1].kor
                                                  }
                                                </span>

                                                    </div>
                                                    <div className='c-left-bottom'>
                                                        {
                                                            n.sections && n.sections.length > 3 && ctx.lang === 'cn' ?
                                                                n.sections[2].cn
                                                                :
                                                                n.sections[2].kor
                                                        }
                                                    </div>
                                                </div>
                                                <div className='c-right'>
                                                    <img className='c-right-img' src={this.getImageUrl(n)}/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>


                        })
                    }
                </div>
            }
        </div>
    }
}

export default News;