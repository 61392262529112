import React from 'react'
import '../common/Page.scss';
import TopZone from "../common/TopZone";
import AboutHgBig from "../about/AboutHgBig";
import './hr.scss'
import HrJiaren from "./HrJiaren";
import {ctx} from "../common/Ctx";
class Hr extends React.Component<any, any> {


    render() {

        return <div className='page'>
            <TopZone text={<div>
                <div>
                    {ctx.s('公司贯彻')}

                </div>
                <div>
                    {ctx.s('以人力资源战略')}
                </div>

            </div>} bgImg='/tech/banner1.jpg' iconImg='/tech/icon1.svg'/>
            <div className='page-wrap page-wrap-color-white about-bg-wangge' style={{height: 550, zIndex: 999}}>
                <div className='page-center'>
                    <AboutHgBig top={-200} left={233} width={900} height={900} pic={''} logo={''}>
                        <div className='about-hg-icon' style={{
                            backgroundImage: 'url(/hr/hr2.svg)',
                            width: 80,
                            height: 80,
                            left: 408,
                            top: 138
                        }}>&nbsp;</div>

                        <div className='tan-hua-jian-jie'>
                            <div className='tan-hua-jian-jie-title'>
                                {ctx.s('人才理念')}
                            </div>
                            <div className='tan-hua-jian-jie-content'>
                                {ctx.s('公司贯彻人才强企战略')}
                            </div>

                            <div className='tan-hua-jian-jie-content'>
                                {ctx.s('围绕人才强企战略')}
                            </div>

                        </div>
                    </AboutHgBig>
                </div>
            </div>

            <div className='page-wrap  page-wrap-color-white hr-zhaopin' style={{height: 500, zIndex: 999}}>
                <div className='page-center'>
                    <div className='hr-zhaopin-content'>
                        <div className='hr-zhaopin-icon'></div>
                        <div className='hr-zhaopin-text-zone'>
                            <div className='hr-zhaopin-text-title'>

                                {ctx.s('人才招聘')}
                            </div>
                            <div className='hr-zhaopin-text'>
                                <p className='hr-zhaopin-text-p'>
                                    {ctx.s('公司诚邀所有致力')}
                                </p>
                                <p className='hr-zhaopin-text-p'>
                                    {ctx.s('招聘流程')}
                                </p>
                                <p className='hr-zhaopin-text-p'>
                                    {ctx.s('将简历发送至')}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className=' page-wrap   hr-jiaren' style={{height: 800, zIndex: 999}}>
                <div className='page-center'>
                    <div className='hr-jiaren-content'>
                        <div className='hr-jiaren-icon'></div>
                        <div className='hr-jiaren-title'>{ctx.s('家人互动')}</div>
                        <HrJiaren/>
                    </div>

                </div>
            </div>

        </div>
    }
}

export default Hr;