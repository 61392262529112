import React, {RefObject} from 'react'
import './Wheel.scss';
import {uuid} from "../common/UUID";
import {hexagonManager} from "../common/HexagonManager";
import Animate from "../animate/Animate";
import WheelHexagon from "./WheelHexagon";
import {ctx} from "../common/Ctx";

class Wheel extends React.Component<any, any> {

    wheelHgRef: React.RefObject<any>


    constructor(props: any) {
        super(props);
        this.wheelHgRef = React.createRef();
    }

    index=0
    nextIndex=1

    state = {}

    currentColor: string = 'rgba(0,0,0,0.5)';
    notCurrentColor: string = 'rgba(180,180,180,0.5)';
    indexBg: any [] = ['img/index/wheel1.jpg', 'img/index/wheel2.jpg'];

    wheelText: any [] = [[ctx.s('打造专业00'), ctx.s('高端散热材料')], [ctx.s('散热整体解决方案'), ctx.s('引领者')]];

    filterId: string = uuid.create();
    pathFilterId: string = uuid.create();
    clipId0: string = uuid.create();
    wheelId: string = uuid.create();
    wheelHeight: number = 0;
    wheelWidth: number = 0;
    viewBoxWidth: number = 0;

    p0Id: string = uuid.create();
    interval: number = 0;

    getWheelText = (idx: any) => {

        let wheelText: any [] = [[ctx.s('打造专业00'), ctx.s('高端散热材料')], [ctx.s('散热整体解决方案'), ctx.s('引领者')]];
        return wheelText[idx]

    }

    componentDidMount(): void {
        window.addEventListener('resize', this.findSize)
        this.interval = window.setInterval(this.onInterval, 4000)
        this.findSize();
    }

    componentWillUnmount(): void {
        window.removeEventListener('resize', this.findSize)
        window.clearInterval(this.interval)
    }




    findSize = () => {
        let wheel: HTMLElement | null = document.getElementById(this.wheelId);
        if (wheel) {
            this.wheelWidth = wheel.offsetWidth;
            this.wheelHeight = wheel.offsetHeight;
            this.viewBoxWidth = this.wheelWidth / this.wheelHeight * 100;
            this.setState({})
        }

    }
    componentDidUpdate() {
        this.wheelHgRef.current.draw(this.getWheelText(this.nextIndex));
    }

    onInterval = () => {
        this.wheelHgRef.current.hide();
        // this.setState({}, () => {
        //
        //
        // })
        let pol: any = document.getElementById(this.p0Id)
        let drawed: boolean = false;
        new Animate(3000, (pst: number) => {
            hexagonManager.draw(this.p0Id, 200 * pst, this.viewBoxWidth / 2, 50, 5)
            pol.setAttribute('transform', `rotate(${360 * pst * 0.1},${this.viewBoxWidth / 2} ${50})`)
            if (!drawed) {
                drawed = true;
                this.wheelHgRef.current.draw(this.getWheelText(this.nextIndex));
            }
        }, () => {
            hexagonManager.draw(this.p0Id, 0, this.viewBoxWidth / 2, 50, 0)

            let index = this.index
            let nextIndex = this.nextIndex

            this.index=nextIndex
            this.nextIndex=index
            // @ts-ignore
            document.getElementById('idx_img0').setAttribute('xlink:href',this.indexBg[this.nextIndex])
            // @ts-ignore
            document.getElementById('idx_img1').setAttribute('xlink:href',this.indexBg[this.index])
        }).start();
    }


    toIndex = (idx: number) => {
        this.onInterval();
    }

    render() {

        return <div id={this.wheelId} className='wheel'>
            <svg className='wheel-svg' version="1.1" viewBox={`0 0 ${this.viewBoxWidth} 100`}>

                <defs>
                    <mask id={this.clipId0} x="0" y="0" width="200%" height="200%">
                        <path fill='#ffffff' id={this.p0Id} d=""/>
                    </mask>
                </defs>

                <image preserveAspectRatio="xMinYMin" x='0' y='0' width={this.viewBoxWidth} id='idx_img0'
                       height={100 * (this.wheelWidth / this.wheelHeight)}
                       xlinkHref={this.indexBg[this.nextIndex]}/>

                <image mask={`url(#${this.clipId0})`} preserveAspectRatio="xMinYMin" x='0' y='0' id='idx_img1'
                       width={this.viewBoxWidth}
                       height={100 * (this.wheelWidth / this.wheelHeight)} xlinkHref={this.indexBg[this.index]}/>


            </svg>


            <div className='wheel-center'>
                <WheelHexagon ref={this.wheelHgRef}/>
                <div className='wheel-button-group' style={{display:'none'}}>
                    <div onClick={this.toIndex.bind(this, 0)} className='wheel-button'>
                        <svg version="1.1" viewBox="0 0 100 100">
                            <circle className='wheel-button-circle' cx="50" cy="50" r="50"
                                    fill={this.index === 0 ? this.currentColor : this.notCurrentColor}/>
                        </svg>
                    </div>
                    <div onClick={this.toIndex.bind(this, 1)} className='wheel-button'>
                        <svg version="1.1" viewBox="0 0 100 100">
                            <circle className='wheel-button-circle' cx="50" cy="50" r="50"
                                    fill={this.index === 1 ? this.currentColor : this.notCurrentColor}/>
                        </svg>
                    </div>
                </div>
            </div>

        </div>
    }
}

export default Wheel;