import React from 'react'
import './EditNews.scss'
import axios from "axios";
import { api } from '../common/api';

class EditNews extends React.Component<any, any> {

    state = {
        list: [],
        addNewsId: '',

        editNewsTitleId: '',
        editSecId: '',
        newSec: {
            cn: '',
            kor: ''
        },
        editSec: {
            id: '',
            cn: '',
            kor: ''
        }

    }

    componentDidMount() {
        this.initPage();
    }

    onBtnClick = () => {
        let news = {
            title: '你好',
            id: 'ooo',
        }
        axios.post(`${api.prefix}add_news`, news).then((res) => {
                console.log(res)
                this.initPage()
            }
        ).catch((err) => {
            this.setState({currentEdit: ''})
        })
    }

    initPage = () => {
        axios.get(`${api.prefix}list_news`, {})
            .then(res => {
                if (res.status === 200) {
                    console.log('res', res.data)
                    this.setState({
                        list: res.data,
                        addNewsId: '',
                        editNewsTitleId: '',
                        editSecId: '',
                        newSec: {},
                        editSec: {
                            id: '',

                        }
                    })

                }
            })
    }

    onAddSec = (addNewsId: any) => {
        this.setState({addNewsId: addNewsId})
    }

    onEditTitle = (editNewsTitleId: any) => {
        this.setState({editNewsTitleId: editNewsTitleId})
    }

    onEditTitleSubmit = () => {
        try {
            let edNews: any = this.state.list.find((n: any) => n.id === this.state.editNewsTitleId)
            axios.post(`${api.prefix}update_title`, edNews)
                .then((res: any) => {

                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }


    onAddSecSubmit = () => {
        try {
            let newSec = {
                cn: this.state.newSec.cn,
                kor: this.state.newSec.kor,
                news: {
                    id: this.state.addNewsId
                }
            }
            axios.post(`${api.prefix}add_sec`, newSec)
                .then((res: any) => {
                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }

    onDeleteSecSubmit = (secId: any) => {
        try {

            axios.post(`${api.prefix}delete_sec`, {id: secId})
                .then((res: any) => {
                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }

    onEditSec = (sec: any) => {
        this.setState({editSec: sec})
    }
    onEditSecSubmit = () => {
        try {
            axios.post(`${api.prefix}edit_sec`, this.state.editSec)
                .then((res: any) => {
                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }

    onFileChange = (newsId: any, e: any) => {
        try {
            if (e.target && e.target.files && e.target.files.length > 0 && e.target.files[0]) {
                let formData: FormData = new FormData()
                formData.append('file', e.target.files[0])
                formData.append('newsId', newsId)
                axios.post(`${api.prefix}upload_img`, formData)
                    .then((res: any) => {
                            this.initPage()
                        }
                    )
                    .catch((err) => {
                        this.setState({editNewsTitleId: ''})
                    })


            }
        } catch (e) {
            console.error(e)
        }
    }

    onDeleteImg = (imgId: any) => {
        try {
            axios.post(`${api.prefix}delete_img`, {id: imgId})
                .then((res: any) => {
                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }

    onDeleteNews = (id: any) => {
        try {
            axios.post(`${api.prefix}delete_news`, {id: id})
                .then((res: any) => {
                        this.initPage()
                    }
                )
                .catch((err) => {
                    this.setState({editNewsTitleId: ''})
                })
        } catch (e) {
            console.error(e)
        }
    }


    render() {

        return <div className='edit-news'>

            <table className='edit-table'>
                <thead>
                <tr>
                    <th style={{width: 80}}>
                        操作
                    </th>
                    <th style={{width: 180}}>
                        新闻分类
                    </th>
                    <th style={{width: '50%'}}>
                        主副标题,段落
                    </th>
                    <th>
                        图片
                    </th>
                </tr>

                </thead>
                <tbody>
                {
                    this.state.list.map((n: any) => {
                        return <tr key={n.id}>
                            <td>
                                <button onClick={this.onDeleteNews.bind(this, n.id)}>删除新闻</button>
                            </td>
                            <td>
                                <div className='title-container'>
                                    {
                                        this.state.editNewsTitleId === n.id ?
                                            <div>
                                                <select value={n.newsType} onChange={(evt: any) => {
                                                    try {
                                                        console.log(evt.target.value)
                                                        let edNews: any = this.state.list.find((nn: any) => nn.id === n.id)
                                                        edNews.newsType = evt.target.value
                                                        this.setState({list: this.state.list})
                                                    } catch (ex) {
                                                        console.error(ex)
                                                    }
                                                }}>
                                                    <option value=''></option>
                                                    <option value='行业动态'>行业动态</option>
                                                    <option value='公司新闻'>公司新闻</option>
                                                    <option value='专题报道'>专题报道</option>
                                                </select>
                                                <div>
                                                    <button onClick={this.onEditTitleSubmit}>确定</button>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <div>
                                                  {n.newsType}
                                                </div>
                                                <div>
                                                    <button onClick={this.onEditTitle.bind(this, n.id)}>编辑</button>
                                                </div>
                                            </div>
                                    }


                                </div>
                            </td>
                            <td>
                                <div className='sec-container'>
                                    {n.sections && n.sections.map((s: any) => {
                                        return <div key={s.id} className='sec-container-list'>
                                            <div className='sec-container-list-item'>
                                                <div className='sec-container-list-item-op'>
                                                    <button onClick={this.onEditSec.bind(this, s)}> 编辑</button>
                                                    <button onClick={this.onDeleteSecSubmit.bind(this, s.id)}>
                                                        删除
                                                    </button>
                                                </div>
                                                {
                                                    this.state.editSec && this.state.editSec.id === s.id ?
                                                        <div className='add-sec-container'>
                                                            <div className='add-sec-container-text'>
                                                                <div className='add-sec-item'>
                                                                    <div>中文</div>
                                                                    <div>
                                                            <textarea value={this.state.editSec.cn}
                                                                      onChange={(evt: any) => {
                                                                          this.state.editSec.cn = evt.target.value
                                                                          this.setState({editSec: this.state.editSec})
                                                                      }}
                                                                      className='add-sec-textarea' rows={10}/>
                                                                    </div>
                                                                </div>
                                                                <div className='add-sec-item'>
                                                                    <div>韩文</div>
                                                                    <div>
                                                            <textarea value={this.state.editSec.kor}
                                                                      onChange={(evt: any) => {
                                                                          this.state.editSec.kor = evt.target.value
                                                                          this.setState({editSec: this.state.editSec})
                                                                      }}
                                                                      className='add-sec-textarea' rows={10}/>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <button onClick={this.onEditSecSubmit}>确定</button>
                                                                <button onClick={this.onEditSec.bind(this, {})}>取消
                                                                </button>
                                                            </div>
                                                        </div>
                                                        :
                                                        <div className='sec-container-list-item-ta'>
                                                            <div className='sec-container-list-item-content'>
                                                                {s.cn}
                                                            </div>
                                                            <div className='sec-container-list-item-content'>
                                                                {s.kor}
                                                            </div>
                                                        </div>
                                                }
                                            </div>
                                        </div>
                                    })}
                                    {
                                        this.state.addNewsId === n.id ? <div className='add-sec-container'>
                                                <div className='add-sec-container-text'>
                                                    <div className='add-sec-item'>
                                                        <div>中文</div>
                                                        <div>
                                                            <textarea value={this.state.newSec.cn}
                                                                      onChange={(evt: any) => {
                                                                          this.state.newSec.cn = evt.target.value
                                                                          this.setState({newSec: this.state.newSec})
                                                                      }}
                                                                      className='add-sec-textarea' rows={10}/>
                                                        </div>
                                                    </div>
                                                    <div className='add-sec-item'>
                                                        <div>韩文</div>
                                                        <div>
                                                            <textarea value={this.state.newSec.kor}
                                                                      onChange={(evt: any) => {
                                                                          this.state.newSec.kor = evt.target.value
                                                                          this.setState({newSec: this.state.newSec})
                                                                      }}
                                                                      className='add-sec-textarea' rows={10}/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div>
                                                    <button onClick={this.onAddSecSubmit}>确定</button>
                                                    <button onClick={this.onAddSec.bind(this, '')}>取消</button>
                                                </div>
                                            </div>
                                            :
                                            <div>
                                                <button onClick={this.onAddSec.bind(this, n.id)}>添加段落</button>
                                            </div>
                                    }
                                </div>
                            </td>
                            <td>
                                <div className='img-container'>
                                    <div className='op-container'>
                                        <input type='file' onChange={this.onFileChange.bind(this, n.id)}/>
                                    </div>
                                    <div className='img-item-container'>
                                        {
                                            n.images && n.images.map((img: any) => {
                                                return <div key={img.id} className='img-item'>
                                                    <img className='img-content'
                                                         src={`${api.prefix}news_img?imgId=${img.id}`}/>
                                                    <button onClick={this.onDeleteImg.bind(this, img.id)}>删除图片</button>
                                                </div>
                                            })
                                        }
                                    </div>
                                </div>
                            </td>
                        </tr>
                    })
                }
                </tbody>

            </table>
            <button onClick={this.onBtnClick}>添加新闻</button>
        </div>

    }

}

export default EditNews;