import {isIE} from "./IEUtils";

class AnimateUtil {

    startTime: number = 0;
    callBack: Function | undefined = undefined;
    duration: number = 0;
    endCallBack: Function | undefined = undefined;

    constructor(duration: number, callBack: Function, endCallBack: Function | undefined=undefined) {
        try {
            this.callBack = callBack;
            this.duration = duration;
            this.endCallBack = endCallBack;

        } catch (e) {
            console.log(e)
        }
    }


    start = () => {
        try {
            if (!isIE && window.requestAnimationFrame) {
                this.startTime = new Date().getTime();
                window.requestAnimationFrame(this.animateFrame)
            } else {//如果不支持动画则直接发送动画完毕
                if (this.callBack) {
                    this.callBack(1);
                }
                if (this.endCallBack) {
                    this.endCallBack();
                }
            }


        } catch (e) {
            console.log(e)
        }

    }

    animateFrame = () => {
        try {

            let currentTime = new Date().getTime();
            let lifePst: number = (currentTime - this.startTime) / this.duration;
            if (lifePst > 1) {
                lifePst = 1;
            }
            if (this.callBack) {
                this.callBack(lifePst)
            }

            if (lifePst < 1) {
                window.requestAnimationFrame(this.animateFrame)
            } else {
                if (this.endCallBack) {
                    this.endCallBack();
                }
            }
        } catch (e) {
            console.log(e)
        }

    }


}


export default AnimateUtil;