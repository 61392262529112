import React from 'react'
import './WheelHexagon.scss';
import {hexagonManager} from "../common/HexagonManager";
import Animate from "../animate/Animate";
import SequenceAnimateGroup from "../animate/SequenceAnimateGroup";
import {uuid} from "../common/UUID";
import MagicFont from "../common/MagicFont";

class WheelHexagon extends React.Component<any, any> {
    r = 10;
    frameId: string = uuid.create()
    font1: React.RefObject<MagicFont>
    font2: React.RefObject<MagicFont>

    constructor(props: any) {
        super(props);
        this.font1 = React.createRef();
        this.font2 = React.createRef();
    }

    componentDidMount(): void {
        this.draw(['打造专业', '高端散热器材']);
    }

    hide = () => {
        // @ts-ignore
        this.font1.current.disPlayStr('');
        // @ts-ignore
        this.font2.current.disPlayStr('');
        hexagonManager.draw('pol', (100) * 0, 125, 125, 0);
        hexagonManager.draw('pol1', (90) * 0, 125, 125, 0);
        hexagonManager.draw('pol2', (80) * 0, 125, 125, 0);
        hexagonManager.draw('pol3', (70) * 0, 125, 125, 0);
        hexagonManager.draw('pol4', (60) * 0, 125, 125, 0);
        hexagonManager.draw('pol5', (60) * 0, 125, 125, 0);

    }

    draw = (texts: any) => {

        let an1: Animate = new Animate(500, this.ani1Frame, () => {
            // @ts-ignore
            this.font1.current.disPlayStr(texts[0]);
            // @ts-ignore
            this.font2.current.disPlayStr(texts[1]);

        });
        an1.start();
    }


    ani1Frame = (lifePst: number): any => {
        hexagonManager.draw('pol', (100) * lifePst, 125, 125, this.r);
        hexagonManager.draw('pol1', (90) * lifePst, 125, 125, this.r);
        hexagonManager.draw('pol2', (80) * lifePst, 125, 125, this.r);
        hexagonManager.draw('pol3', (70) * lifePst, 125, 125, this.r);
        hexagonManager.draw('pol4', (60) * lifePst, 125, 125, this.r);
        hexagonManager.draw('pol5', (60) * lifePst, 125, 125, this.r);
    }



    render() {
        //做散热整体解决方案 引领者
        return <div className='wheel-hexagon' id={this.frameId}>
            <svg id="svg1" version="1.1" viewBox="0 0 250 250">
                <defs>
                    <filter id='time-idx-fid1' x="-50%" y="-50%" width="200%" height="200%">
                        <feOffset result="offOut" in="SourceGraphic" dx="5" dy="5"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>
                    <linearGradient filter='url(#time-idx-fid1)' gradientUnits="userSpaceOnUse" id="linearGradient_x1_1" x1="0" y1="0" x2="200"
                                    y2="200">

                        <stop offset="0%" stopColor='#c79735'/>
                        <stop offset="25%" stopColor='#c79735'/>
                        <stop offset="50%" stopColor='#784485'/>
                        <stop offset="75%" stopColor='#d46417'/>
                        <stop offset="100%" stopColor='#c79735'/>
                        <animateTransform
                            attributeName="gradientTransform"
                            begin="0s"
                            dur="5s"
                            type="rotate"
                            from="0 100 100"
                            to="360 100 100"
                            repeatCount="indefinite"
                        />
                    </linearGradient>
                    <mask id="hexagonal-clip-path"  filter='url(#time-idx-fid1)'>
                        <g>
                            <path      filter='url(#time-idx-fid1)' className='polygon' id="pol" d=""/>
                            <path      filter='url(#time-idx-fid1)' className='polygon' id="pol1" d=""/>
                            <path      filter='url(#time-idx-fid1)' className='polygon' id="pol2" d=""/>
                            <path      filter='url(#time-idx-fid1)' className='polygon' id="pol3" d=""/>
                            <path      filter='url(#time-idx-fid1)' className='polygon' id="pol4" d=""/>
                        </g>
                    </mask>

                </defs>

                <path className='polygon-center' id="pol5" d=""/>

                <circle
                    fill='url(#linearGradient_x1_1)'
                    mask="url(#hexagonal-clip-path)"
                    cx="125"
                    cy="125"
                    r="120"
                />
            </svg>

            <div id='whtxt1' className='font0'><MagicFont ref={this.font1}/></div>
            <div id='whtxt2' className='font1'><MagicFont ref={this.font2}/></div>


        </div>
    }
}

export default WheelHexagon;