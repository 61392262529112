import React from 'react'
import './HrJiaren.scss'
import {uuid} from "../common/UUID";
import Animate from "../animate/Animate";
import {hexagonManager} from "../common/HexagonManager";

class HrJiaren extends React.Component<any, any> {

    svgId: string = uuid.create()
    p00Id: string = uuid.create()
    p0Id: string = uuid.create()
    p1Id: string = uuid.create()
    p2Id: string = uuid.create()
    p3Id: string = uuid.create()
    p4Id: string = uuid.create()
    p5Id: string = uuid.create()
    rLong: number = 150;

    mask0Id: string = uuid.create();
    mask1Id: string = uuid.create();
    mask2Id: string = uuid.create();
    mask3Id: string = uuid.create();

    img0Id: string = uuid.create();
    img1Id: string = uuid.create();
    img2Id: string = uuid.create();
    img3Id: string = uuid.create();

    componentDidMount() {
        this.invoke()
    }

    componentWillUnmount() {
        //  document.removeEventListener('scroll', this.invoke)
    }

    invoke = () => {
        new Animate(1000, (pst: number) => {
            let sin30 = Math.sin(30 * Math.PI / 180);
            let cos30 = Math.cos(30 * Math.PI / 180);
            let moveX = this.rLong + sin30 * this.rLong + 8
            let moveY = cos30 * this.rLong + 4
            hexagonManager.draw(this.p0Id, this.rLong * pst, 200, 300 + moveY, 0);
            hexagonManager.draw(this.p1Id, this.rLong * pst, 200 - moveX, 300, 0);
            hexagonManager.draw(this.p2Id, this.rLong * pst, 200 + moveX, 300, 0);
            hexagonManager.draw(this.p3Id, this.rLong * pst, 200, 300 - moveY, 0);

            hexagonManager.draw(this.p4Id, this.rLong * pst /2.5, 60, 430 - moveY, 0);
            hexagonManager.draw(this.p5Id, this.rLong * pst /2.5, 570, 298 - moveY, 0);

        }).start();
    }

    render() {
        let sin30 = Math.sin(30 * Math.PI / 180);
        let cos30 = Math.cos(30 * Math.PI / 180);
        let moveX = this.rLong + sin30 * this.rLong + 8
        let moveY = cos30 * this.rLong + 4
        return <div className='hr-jia-ren-hg'>
            <svg id={this.svgId} width='100%' height='100%' version="1.1" viewBox="0 0 800 600">
                <defs>


                    <mask id={this.mask2Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p2Id}/>
                    </mask>
                    <mask id={this.mask3Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p3Id}/>
                    </mask>
                </defs>

                <path fill='rgba(0,0,0,0.1)' stroke='rgba(255,255,255,0.1)' strokeWidth='1.5' id={this.p0Id}/>


                <path fill='none' stroke='rgba(255,255,255,0.5)' strokeWidth='1.5' id={this.p4Id}/>

                <path fill='none' stroke='rgba(255,255,255,0.5)' strokeWidth='1.5' id={this.p5Id}/>


                <image
                    mask={`url(#${this.mask2Id})`}
                    x={(200 + moveX) - (this.rLong * 2.6 / 2)}
                    y={(300) - (this.rLong * 2.6 / 2)}
                    id={this.img2Id}

                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/hr/hr5.jpg'/>

                <image
                    mask={`url(#${this.mask3Id})`}
                    x={200 - (this.rLong * 2.6 / 2)}
                    y={(300 - moveY) - (this.rLong * 2.6 / 2)}
                    id={this.img3Id}
                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/hr/hr6.jpg'/>

                <circle r='2' cx='400' cy='300' fill='red'/>
            </svg>
        </div>
    }
}

export default HrJiaren;