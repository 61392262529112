import React from 'react'
import {ctx} from '../common/Ctx';
import './Admin.scss'
import {Link} from "react-router-dom";

class Admin extends React.Component<any, any> {


    state = {
        account: '',
        password: ''
    }

    onLogin = () => {
        if (this.state.account === 'admin' && this.state.password === '123456') {
            ctx.isLogin = true;
            this.setState({})
        } else {
            alert('账号或密码错误!')
        }
    }


    render() {
        return <div className='admin'>
            {ctx.isLogin ?
                <div>
                    <ul>
                        <li>
                            <Link to='/edit'>网站文案管理(中,韩)</Link>
                        </li>
                        <li>
                            <Link to='/edit_image'>网站图片管理</Link>
                        </li>
                        <li>
                            <Link to='/edit_news'>新闻管理(中,韩)</Link>
                        </li>
                    </ul>
                    <button onClick={() => {
                        ctx.isLogin = false
                        this.setState({})
                    }
                    }>退出登录
                    </button>

                </div>
                :
                <table className='ad-table'>
                    <tbody>
                    <tr>
                        <td>账号</td>
                        <td>
                            <input value={this.state.account}
                                   onChange={(e) => {
                                       this.setState({account: e.target.value})
                                   }
                                   }

                            /></td>
                    </tr>
                    <tr>
                        <td>密码</td>
                        <td><input type='password'
                                   value={this.state.password}
                                   onChange={(e) => {
                                       this.setState({password: e.target.value})
                                   }
                                   }

                        /></td>
                    </tr>
                    <tr>
                        <td colSpan={2}>
                            <button onClick={this.onLogin}>登录</button>
                        </td>
                    </tr>
                    </tbody>
                </table>


            }


        </div>;
    }


}

export default Admin;