import Animate from "./Animate";

class SequenceAnimateGroup {


    animates: any = []

    addAnimate = (animate: Animate): void => {
        this.animates.push(animate)
    }

    start = () => {
        this.doInAnimate(0);
    }

   private doInAnimate = (idx: number) => {
        try {
            let ani: Animate = this.animates[idx];
            let aniCallBack: any = ani.endCallBack;
            ani.endCallBack = () => {
                if (aniCallBack) {
                    aniCallBack();
                }
                if (idx + 1 < this.animates.length) {
                    this.doInAnimate(idx + 1)
                }
            }

            ani.start();

        } catch (e) {
            console.error(e)
        }


    }


}

export default SequenceAnimateGroup;