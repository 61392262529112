import React from 'react'
import '../common/Page.scss';
import TopZone from "../common/TopZone";
import AboutHgBig from "./AboutHgBig";
import './About.scss'
import AboutMubiaoItem from "./AboutMubiaoItem";
import AboutHgDongShiZhang from "./AboutHgDongShiZhang";
import ShengChanJidiHg from "./ShengChanJidiHg";
import {ctx} from "../common/Ctx";

class About extends React.Component<any, any> {


    render() {


        return <div className='page'>
            <TopZone bgImg='/about/top-bg.jpg' iconImg='/about/top-icon.svg'/>


            <div className='page-wrap page-wrap-color-white about-bg-wangge' style={{height: 750, zIndex: 999}}>
                <div className='page-center'>
                    <AboutHgBig top={-200} left={233} width={900} height={900} pic={''} logo={''}>
                        <div className='about-hg-icon' style={{
                            backgroundImage: 'url(/about/hg-icon-2.svg)',
                            width: 80,
                            height: 80,
                            left: 405,
                            top: 138
                        }}>&nbsp;</div>

                        <div className='tan-hua-jian-jie'>
                            <div className='tan-hua-jian-jie-title'>
                                {ctx.s('走进碳华2')}
                            </div>
                            <div className='tan-hua-jian-jie-content'>
                                {ctx.s('安徽碳华新材料科技有限公司于2')}
                            </div>

                            <div className='tan-hua-jian-jie-content'>
                                {ctx.s('我们是一家致力于为客户2')}
                            </div>

                        </div>
                    </AboutHgBig>
                    <div className='about-mubiao-container'>
                        <AboutMubiaoItem bg={'/about/item-bg2.jpg'} icon={'/about/item-icon1.svg'}
                                         text= {ctx.s('打造专业高端散热材料2')}
                                         title=    {ctx.s('目标2')}
                        />
                        <AboutMubiaoItem bg={'/about/item-bg1.jpg'} icon={'/about/item-icon2.svg'}
                                         title= {ctx.s('宗旨2')}
                                         text= {ctx.s('科技创新2')}/>
                        <AboutMubiaoItem bg={'/about/item-bg3.jpg'} icon={'/about/item-icon3.svg'}
                                         title= {ctx.s('质量方针2')}
                                         text={ctx.s('以质量安全保证信誉2')}/>
                    </div>
                </div>
            </div>
            <div className='page-wrap page-wrap-color-yellow ' style={{height: 1200, zIndex: 999}}>
                <div className='page-center'>
                    <AboutHgBig top={-120} left={-17} width={1400} height={1400} pic={''} logo={''}>
                        <div className='about-hg-icon' style={{
                            backgroundImage: 'url(/about/hg-icon-1.svg)',
                            width: 80,
                            height: 80,
                            left: 650,
                            top: 215
                        }}>&nbsp;</div>

                        <div className='sheng-chan-ji-di'>
                            <div className='sheng-chan-ji-di-title'>
                                {ctx.s('生产基地')}
                            </div>
                            <div className='sheng-chan-ji-di-content'>
                                {ctx.s('安徽碳华新材料科技有限公司设计')}
                            </div>

                            <div className='sheng-chan-ji-di-content'>
                                {ctx.s('碳华新材生产基地拥有国际一流水准的高导膜生产设施')}
                            </div>


                        </div>
                        <ShengChanJidiHg />
                    </AboutHgBig>
                </div>
            </div>
            <div className='page-wrap dong-shi-zhang-color ' style={{height: 800, zIndex: 999}}>
                <div className='page-center'>
                    <AboutHgDongShiZhang top={-20} left={183} width={1000} height={1000} pic={''} logo={''}>
                        <div className='about-hg-icon' style={{
                            backgroundImage: 'url(/about/hg-icon-3.svg)',
                            width: 80,
                            height: 80,
                            left: 464,
                            top: 154
                        }}>&nbsp;</div>

                        <div className='dong-shi-zhang'>

                            <div className='dong-shi-zhang-content' style={{textIndent:0}}>
                                {ctx.s('董事长致辞')}:
                            </div>
                            <div className='dong-shi-zhang-content'>
                                {ctx.s('我们坚持创新发展')}
                            </div>

                            <div className='dong-shi-zhang-content'>
                                {ctx.s('我们树立了“科技创新')}
                            </div>
                            <div className='dong-shi-zhang-content'>
                                {ctx.s('我们一直努力履行着这样的誓言')}
                            </div>
                            <div className='dong-shi-zhang-content'>
                                {ctx.s('我们愿和所有的朋友携起手来')}
                            </div>
                        </div>
                    </AboutHgDongShiZhang>
                </div>
            </div>
        </div>
    }
}

export default About;