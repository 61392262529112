import React from 'react'
import '../common/Page.scss';
import TopZone from "../common/TopZone";
import AboutHgBig from "../about/AboutHgBig";
import './Contact.scss'
import {ctx} from "../common/Ctx";

class Contact extends React.Component<any, any> {


    render() {

        return <div className='page'>
            <TopZone text={<div>
                <div>
                </div>
                <div>
                </div>
            </div>} bgImg='/contact/con1.jpg' iconImg='/contact/con_icon1.svg'/>

            <div className='page-wrap page-wrap-color-yellow ' style={{height: 1050, zIndex: 999}}>
                <div className='page-center'>
                    <AboutHgBig top={-150} left={133} width={1100} height={1100} pic={''} logo={''}>
                        <div className='con-hg-icon' style={{
                            backgroundImage: 'url(/contact/con_icon2.svg)',
                            width: 80,
                            height: 80,
                            left: 500,
                            top: 210
                        }}>&nbsp;</div>

                        <div className='con-ditu-container'>
                            <div className='con-ditu-container-title'>
                                {ctx.s('安徽碳华新材料科技有限公司')}
                            </div>
                            <div className='con-ditu-container-content '>
                                <div className='con-loc'></div>
                                {ctx.s('地址')}
                            </div>
                            <div className='con-ditu-container-content con-phone'>
                                <div className='con-phone'></div>
                                {ctx.s('电话')}
                            </div>
                            <div className='con-ditu-container-content con-emal'>
                                <div className='con-emal'></div>
                                {ctx.s('邮箱')}
                            </div>
                            <div className='con-ditu-container-tupian'>

                            </div>


                        </div>
                    </AboutHgBig>
                </div>
            </div>


        </div>
    }
    }

    export default Contact;