class HexagonManager {

    movePoint = (p: any, moveX: number, moveY: number): any => {
        p.x = p.x + moveX;
        p.y = p.y + moveY;

        return p;
    }

    //坐标旋转,p 点, d 角度
    rotatePoint = (p: any, d: any): any => {
        let b = d * Math.PI / 180;
        return {
            x: p.x * Math.cos(b) - p.y * Math.sin(b),
            y: p.y * Math.cos(b) + p.x * Math.sin(b)
        };
    }


    createFragment = (p: any, r: any, idx: any): any => {
        let maxIndex = p.length - 1;
        let lineToIndex = idx < maxIndex ? idx + 1 : 0;
        return `
                 A${r},${r},0,0,1 ${p[idx].x},${p[idx].y}
                 L${p[lineToIndex].x},${p[lineToIndex].y}
            `
    }

    // rx,ry x-axis-rotation large-arc-flag,sweepflag x,y
    // 从当前画笔位置开始绘制一条椭圆弧线到（x,y）指定的坐标。
    // rx和ry分别为椭圆弧线水平和垂直方向上的半径。
    // x-axis-rotation指定弧线绕x轴旋转的度数。它只在rx和ry的值不相同是有效果。
    // large-arc-flag是大弧标志位，取值0或1，用于决定绘制大弧还是小弧。
    // sweep-flag用于决定弧线绘制的方向
    // a 10.83991,10.83991,0,0,0-9.38765,5.42
    createD = (p: any, r: any = 50, moveX: number, moveY: number) => {

        let d = `M${p[0].x},${p[0].y}
                     ${this.createFragment(p, r, 1)}
                     ${this.createFragment(p, r, 3)}
                     ${this.createFragment(p, r, 5)}
                     ${this.createFragment(p, r, 7)}
                     ${this.createFragment(p, r, 9)}
                     ${this.createFragment(p, r, 11)}
                     Z
                    `
        return d;

    }

    //计算圆角圆和六角形边的切点
    calcTangentPoint = (p: any, r: any, inverse: boolean = false) => {

        let d = inverse ? -30 : 30;

        let cos = Math.cos(d * Math.PI / 180);
        let sin = Math.sin(d * Math.PI / 180);

        let v2 = r / cos;
        let v1 = p.x - v2;
        let y = sin * r;
        let v3 = cos * r;
        let x = v1 + v3;

        return ({x: x, y: y})
    }


    draw = (id: any, length: any, moveX: any, moveY: any, r: any = 25) => {
        try {

            let originPoint = {x: length, y: 0}
            let points = [];

            let tangentPoint1 = this.calcTangentPoint(originPoint, r, true)
            let tangentPoint2 = this.calcTangentPoint(originPoint, r)

            for (let i = 0; i < 6; i++) {
                let rp1 = this.rotatePoint(tangentPoint1, i * 60)
                let rp2 = this.rotatePoint(tangentPoint2, i * 60)
                rp1 = this.movePoint(rp1, moveX, moveY);
                rp2 = this.movePoint(rp2, moveX, moveY);
                points.push(rp1)
                points.push(rp2)
            }

            let d: string = this.createD(points, r, moveX, moveY)
            let pol: any = document.getElementById(id);
            pol.setAttribute('d', d);

        } catch (e) {
            console.log(e)
        }
    }
}

let hexagonManager: HexagonManager = new HexagonManager();

export {hexagonManager};