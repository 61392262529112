import React from 'react'
import './ShengChanJidiHg.scss'
import {uuid} from "../common/UUID";
import Animate from "../animate/Animate";
import {hexagonManager} from "../common/HexagonManager";

class ShengChanJidiHg extends React.Component<any, any> {

    svgId: string = uuid.create()
    p00Id: string = uuid.create()
    p0Id: string = uuid.create()
    p1Id: string = uuid.create()
    p2Id: string = uuid.create()
    p3Id: string = uuid.create()
    rLong: number = 150;

    mask0Id: string = uuid.create();
    mask1Id: string = uuid.create();
    mask2Id: string = uuid.create();
    mask3Id: string = uuid.create();

    img0Id: string = uuid.create();
    img1Id: string = uuid.create();
    img2Id: string = uuid.create();
    img3Id: string = uuid.create();

    componentDidMount() {
        this.invoke()
    }

    componentWillUnmount() {
        //  document.removeEventListener('scroll', this.invoke)
    }

    invoke = () => {
        new Animate(1000, (pst: number) => {
            let sin30 = Math.sin(30 * Math.PI / 180);
            let cos30 = Math.cos(30 * Math.PI / 180);
            let moveX = this.rLong + sin30 * this.rLong + 8
            let moveY = cos30 * this.rLong + 4
            hexagonManager.draw(this.p0Id, this.rLong * pst, 400, 300 + moveY, 0);
            hexagonManager.draw(this.p1Id, this.rLong * pst, 400 - moveX, 300, 0);
            hexagonManager.draw(this.p2Id, this.rLong * pst, 400 + moveX, 300, 0);
            hexagonManager.draw(this.p3Id, this.rLong * pst, 400, 300 - moveY, 0);


        }).start();
    }

    render() {
        let sin30 = Math.sin(30 * Math.PI / 180);
        let cos30 = Math.cos(30 * Math.PI / 180);
        let moveX = this.rLong + sin30 * this.rLong + 8
        let moveY = cos30 * this.rLong + 4
        // mask={`url(#${this.mask2Id})`}
        return <div className='sheng-chan-ji-di-hg'>
            <svg id={this.svgId} width='100%' height='100%' version="1.1" viewBox="0 0 800 600">
                <defs>
                    <mask id={this.mask0Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p0Id}/>
                    </mask>
                    <mask id={this.mask1Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p1Id}/>
                    </mask>
                    <mask id={this.mask2Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p2Id}/>
                    </mask>
                    <mask id={this.mask3Id} x="0" y="0">
                        <path fill='#ffffff' stroke='#000000' strokeWidth='1.5' id={this.p3Id}/>
                    </mask>
                </defs>


                <image
                    mask={`url(#${this.mask0Id})`}
                    x={400 - (this.rLong * 2.6 / 2)}
                    y={(300 + moveY) - (this.rLong * 2.6 / 2)}
                    id={this.img0Id}
                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/about/jd1.jpg'/>

                <image
                    mask={`url(#${this.mask1Id})`}
                    x={(400 - moveX) - (this.rLong * 2.6 / 2)}
                    y={(300) - (this.rLong * 2.6 / 2)}
                    id={this.img1Id}
                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/about/jd2.jpg'/>

                <image
                    mask={`url(#${this.mask2Id})`}
                    x={(400 + moveX) - (this.rLong * 2.6 / 2)}
                    y={(300) - (this.rLong * 2.6 / 2)}
                    id={this.img2Id}

                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/about/jd3.jpg'/>

                <image
                    mask={`url(#${this.mask3Id})`}
                    x={400 - (this.rLong * 2.6 / 2)}
                    y={(300 - moveY) - (this.rLong * 2.6 / 2)}
                    id={this.img3Id}
                    height={this.rLong * 2.6}
                    width={this.rLong * 2.6}
                    xlinkHref='/about/jd4.jpg'/>

                <circle r='2' cx='400' cy='300' fill='red'/>
            </svg>
        </div>
    }
}

export default ShengChanJidiHg;