import React from 'react'
import './Footer.scss'
import {ctx} from "./Ctx";

class Footer extends React.Component<any, any> {


    render() {

        return <div className='footer'>
            <div className='footer-center'>
                <div className='center-left'>
                    <div>
                        Copyright 2013 www.tanhuagroup.com All rights reserved.
                    </div>
                    <div>
                        沪ICP备19012718号 {ctx.s('营业执照编号')}:91340300MA2T6KFBXC
                    </div>
                </div>
                <div  className='center-right'>
                    <div className='right-icon weibo'></div>
                    <div className='right-icon weixin'></div>
                    <div className='right-icon qq'></div>
                    <div className='right-icon email'></div>
                </div>

            </div>
        </div>
    }
}

export default Footer;