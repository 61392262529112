import React from 'react'
import '../common/Page.scss';
import './Tech.scss'
import TopZone from "../common/TopZone";
import Dashiji from "./Dashiji";
import {ctx} from "../common/Ctx";
import Dashiji_png from "./Dashiji_png";

class Tech extends React.Component<any, any> {


    render() {

        return <div className='page'>
            <TopZone text={<div>
                <div>
                    {ctx.s('已获批授权实用新型专利20项')}
                </div>
                <div>
                    {ctx.s('申报成果一项')}
                </div>

            </div>} bgImg='/tech/banner1.jpg' iconImg='/tech/icon1.svg'/>

            <div className='page-wrap  page-wrap-color-white tech-dashiji' style={{height: 1900, zIndex: 999}}>
                <div className='page-center'>
                    <div className='dashiji-icon'></div>
                    <div className='dashiji-text'>{ctx.s('创新大事记')}</div>
                    <Dashiji_png/>
                </div>
            </div>

            <div className='page-wrap  page-wrap-color-white tech-zhiliangbaozheng' style={{height: 400, zIndex: 999}}>
                <div className='page-center'>
                    <div className='zhiliangbaozheng-item'>
                        <div className='zhiliangbaozheng-icon'></div>
                        <div className='zhiliangbaozheng-title'>{ctx.s('质量保证')}</div>
                        <div
                            className='zhiliangbaozheng-text'>{ctx.s('严格的质量管理体系控制流程')}
                        </div>
                    </div>
                </div>
            </div>

            <div className='page-wrap  page-wrap-color-white ' style={{height: 800, zIndex: 999}}>
                <div className='page-center'>
                    <div className='liucheng-container'>
                        <div className='liucheng'></div>
                    </div>
                </div>
            </div>
            <div className='page-wrap  page-wrap-color-yellow ' style={{height: 1200, zIndex: 999}}>
                <div className='page-center'>
                    <div className='keyanjichu-item'>
                        <div className='keyanjichu-icon'></div>
                        <div className='keyanjichu-title'>
                            {ctx.s('科研基础')}

                        </div>
                        <div className='keyanjichu-content'>
                            {ctx.s('碳华创新团队从事绝缘导热材料研究工作十余年')}
                        </div>
                        <div className='keyanjichu-container'>
                            <div className='keyanjichu-item'>

                            </div>

                        </div>

                    </div>
                </div>
            </div>
            <div className='page-wrap  page-wrap-color-white keyanchengguo' style={{height: 400, zIndex: 999}}>
                <div className='page-center'>
                    <div className='keyanchengguo-item'>
                        <div className='keyanchengguo-icon'></div>
                        <div className='keyanchengguo-title'> {ctx.s('科研成果')}</div>

                    </div>
                </div>
            </div>

            <div className='page-wrap  page-wrap-color-white zhengshu' style={{height: 1000, zIndex: 999}}>
                <div className='page-center'>
                    <div className='zhengshu-item'>
                        <div className='zhengshu-text'>  {ctx.s('已获批授权实用新型专利20项')}</div>
                        <div className='zhengshu-panel'>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs1.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs2.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs3.png)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs4.png)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs5.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs6.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs7.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs8.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs9.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs9.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs11.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs12.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs13.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs14.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs15.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs16.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs17.JPG)'}}></div>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/zs18.JPG)'}}></div>
                        </div>
                        <div className='zhengshu-text'>  {ctx.s('申报成果一项')}</div>
                        <div className='zhengshu-panel'>
                            <div className='zs-item' style={{backgroundImage: 'url(/tech/chengguo1.JPG)'}}></div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    }
}

export default Tech;