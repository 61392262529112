import React from 'react'
import {uuid} from "../common/UUID";
import './HgRxxclBange.scss'
import {hexagonManager} from "../common/HexagonManager";

interface HgRxxclBangeProps {
    top: number;
    left?: number;
    right?: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
    shadow?:boolean;

}

class HgRxxclBange extends React.Component<HgRxxclBangeProps, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();
    filter0Id: string = uuid.create();

    filterId: string = uuid.create();

    componentDidMount(): void {
        hexagonManager.draw(this.p3Id, 180, 200, 200);
        hexagonManager.draw(this.p0Id, 160, 200, 200);
        hexagonManager.draw(this.p1Id, 140, 200, 200);
        document.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            console.log('scroll', scrollTop)

        })
    }

    componentWillUnmount(): void {

    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',
        }

        if (this.props.right) {
            frameStyle.right = this.props.right;
        } else {
            frameStyle.left = this.props.left;
        }


        return <div style={frameStyle}>
            <svg version="1.1" viewBox="0 0 400 400">
                <defs>
                    <filter id={this.filter0Id} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceAlpha" dx="10" dy="10" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <path fill='#c79735' stroke='#eeeeee' strokeWidth='0.5' id={this.p3Id}/>
                <path fill='#eeeeee' stroke='none' id={this.p0Id}/>
                <path filter={this.props.shadow?`url(#${this.filter0Id})`:''} strokeWidth='0.5' fill='#eeeeee' stroke='none' id={this.p1Id}/>


            </svg>
        </div>
    }
}

export default HgRxxclBange;