import React from 'react'
import './EditImage.scss'
import axios from "axios";
import { api } from '../common/api';

class EditImage extends React.Component<any, any> {
    state = {list: []}

    componentDidMount() {

        this.initPage();

    }

    initPage = () => {
        axios.get(`${api.prefix}get_img_src`, {})
            .then(res => {
                if (res.status === 200) {
                    console.log('res', res.data)
                    this.setState({
                        list: res.data,
                    })
                }
            })
    }

    onFileChange = (img: any, e: any) => {
        try {
            if (e.target && e.target.files && e.target.files.length > 0 && e.target.files[0]) {
                let formData: FormData = new FormData()
                formData.append('file', e.target.files[0])
                formData.append('fileUrl', img.fileUrl)
                axios.post(`${api.prefix}replace_img`, formData)
                    .then((res: any) => {
                            this.initPage()
                        }
                    )
                    .catch((err) => {
                        this.setState({editNewsTitleId: ''})
                    })


            }
        } catch (e) {
            console.error(e)
        }
    }

    render() {
        return <table className='edit-image-container'>
            <thead>

            <tr>
                <th>路径</th>
                <th>图片</th>
                <th>操作</th>
            </tr>

            </thead>
            <tbody>
            {
                this.state.list && this.state.list.map((img: any) => {
                    return <tr>
                        <td>{img.shortUrl}</td>
                        <td>
                            <div className='edit-img-item' style={{
                                backgroundImage: `url(${api.prefix}img_data?url=${img.fileUrl})`
                            }}>
                            </div>
                        </td>
                        <td><input onChange={this.onFileChange.bind(this, img)} type='file'/></td>
                    </tr>
                })

            }

            </tbody>

        </table>;
    }
}

export default EditImage;