import React from 'react'
import './TopZone.scss'

interface TopZoneProps {
    bgImg: string
    iconImg: string
    text?: any

}

class TopZone extends React.Component<TopZoneProps, any> {


    render() {
        return <div className='page-wrap page-wrap-color-white product-top-img'
                    style={{
                        height: 400,
                        zIndex: 1,
                        backgroundImage: `url(${this.props.bgImg})`
                    }}>
            <div className='page-center'>
                <div className='top-icon-svg' style={{backgroundImage: `url(${this.props.iconImg})`}}></div>
                <div className='top-zone-text'>
                    {
                        this.props.text
                    }
                </div>

            </div>
        </div>

    }
}

export default TopZone;