import React from 'react'
import {uuid} from "../common/UUID";
import './CyGroup.scss'
import {hexagonManager} from "../common/HexagonManager";
import SequenceAnimateGroup from "../animate/SequenceAnimateGroup";
import Animate from "../animate/Animate";

interface CyGroupProps {
    pic: string;
    invokerY?: number;

    text: string;
    aniDu?: number;
    color?:string
}

class CyGroup extends React.Component<CyGroupProps, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    clipId0: string = uuid.create();
    filterId: string = uuid.create();
    pathFilterId: string = uuid.create();
    containerId: string = uuid.create();

    r = 55;
    logoHeight: number = 100;
    textId: string = uuid.create();
    iconId: string = uuid.create();

    invokered: boolean = false;
    height: number = 140
    width: number = 160;

    componentDidMount() {

        document.addEventListener('scroll', this.invoke);
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.invoke)
    }


    invoke = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        console.log(scrollTop)

        if (!this.invokered && this.props.invokerY && scrollTop > this.props.invokerY) {
            this.invokered = true;
            let group: SequenceAnimateGroup = new SequenceAnimateGroup();
            group.addAnimate(new Animate(300, this.step1));

            group.start();
        }
    }


    step1 = (pst: number) => {
        hexagonManager.draw(this.p0Id, this.width / 2 * pst * 0.8, this.width / 2, this.width / 2, this.r);
    }


    render() {

        let frameStyle: React.CSSProperties = {
            width: this.width,
            height: this.width,
            zIndex: 99
        }


        return <div id={this.containerId} style={frameStyle} className='cy-group'>

            <svg className='cy-group-svg' viewBox={`0 0 ${this.width} ${this.width}`} width={this.width}
                 height={this.width}>
                <defs>
                    <filter id={this.pathFilterId} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceGraphic" dx="3" dy="3"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="3"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>
                    <mask id={this.clipId0} x="0" y="0" width="200%" height="200%">
                        <path filter={`url(#${this.pathFilterId})`} fill='#ffffff' id={this.p0Id} d=""/>
                    </mask>
                </defs>


                <image x='-10%' y='-10%'
                       filter={`url(#${this.filterId})`}
                       height="120%" width="120%"
                       mask={`url(#${this.clipId0})`}
                       xlinkHref={this.props.pic}/>


                <path fill='none' strokeWidth='1' stroke='#F6DD64' id={this.p1Id} d=""/>


            </svg>
            <div className='so-cy-text-block' style={{color:this.props.color}}>{this.props.text}</div>
        </div>
    }
}

export default CyGroup;