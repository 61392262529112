import React from 'react'
import {uuid} from "../common/UUID";
import './AboutHgBig.scss'
import {hexagonManager} from "../common/HexagonManager";
interface AboutHgBigProps {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    textZone?: any;
}
class AboutHgDongShiZhang extends React.Component<AboutHgBigProps, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();

    filter0Id: string = uuid.create();

    componentDidMount(): void {
        hexagonManager.draw(this.p3Id, 180, 200, 200);
        hexagonManager.draw(this.p0Id, 180, 200, 200);
        hexagonManager.draw(this.p1Id, 160, 200, 200);
    }

    componentWillUnmount(): void {

    }


    render() {


        let textStyle: React.CSSProperties = {
            lineHeight: `${this.props.lineHeight}px`,
            fontSize: `${this.props.fontSize}px`,

        }

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',
            zIndex:99

        }


        return <div style={frameStyle} className='about-hg-big' >
            <svg version="1.1" width={this.props.width} height={this.props.height}  viewBox="0 0 400 400">
                <defs>
                    <filter id={this.filter0Id} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceAlpha" dx="3" dy="3" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="13" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <path  fill='#717071' stroke='none' id={this.p3Id} d=""/>
                <path fill='none' stroke='#eeeeee' strokeWidth='0.5' id={this.p0Id} d=""/>
                <path filter={`url(#${this.filter0Id})`} strokeWidth='0.5' fill='#eeeeee' stroke='none' id={this.p1Id} d=""/>
            </svg>
            <div className='about-text-zone' style={textStyle}>
                {
                    this.props.children
                }
            </div>

        </div>
    }
}

export default AboutHgDongShiZhang;