import React from 'react'
import {uuid} from "../common/UUID";
import './Hg5.scss'
import {hexagonManager} from "../common/HexagonManager";
import {futimes} from "fs";

interface Hg5Props {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
    clsNm: string;
    onClick?:any;
}


class Hg5 extends React.Component<Hg5Props, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();

    filterId: string = uuid.create();

    componentDidMount(): void {

        hexagonManager.draw(this.p3Id, 180, 200, 200);
        hexagonManager.draw(this.p0Id, 180, 200, 200);
        hexagonManager.draw(this.p1Id, 160, 200, 200);
        document.addEventListener('scroll', this.onScroll)

    }

    componentWillUnmount(): void {
        document.removeEventListener('scroll', this.onScroll)
    }

    onScroll = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',

        }


        return <div style={frameStyle} className='hg5' onClick={this.props.onClick}>
            <svg version="1.1" viewBox="0 0 400 400">
                <defs>
                    <filter id={this.filterId} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceAlpha" dx="10" dy="10"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>
                </defs>
                <path filter={`url(#${this.filterId})`} fill='rgba(0,0,0,0.6)' stroke='none' id={this.p3Id} d=""/>
                <path fill='#ffffff' id={this.p0Id} d=""/>
                <path strokeWidth='1' className={`${this.props.clsNm}`}  id={this.p1Id} d=""/>
            </svg>
            <div className='hg5-text-zone'>
                <div className={`hg5-text ${this.props.clsNm}`}>{this.props.text}</div>
            </div>

        </div>
    }
}

export default Hg5;