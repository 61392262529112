import React from 'react'
import Hexagon from "../common/Hexagon";
import {uuid} from "../common/UUID";
import './Hg4Rxxcl.scss'
import {hexagonManager} from "../common/HexagonManager";
interface Hg4RxxclProps {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
}
class Hg4Rxxcl extends  React.Component<Hg4RxxclProps, any>  {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();
    filter0Id:string=uuid.create();

    filterId: string = uuid.create();

    componentDidMount(): void {
        hexagonManager.draw(this.p3Id, 180, 200, 200);
        hexagonManager.draw(this.p0Id, 160, 200, 200);
        hexagonManager.draw(this.p1Id, 140, 200, 200);
        document.addEventListener('scroll', () => {
            let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
            console.log('scroll', scrollTop)

        })
    }

    componentWillUnmount(): void {

    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',

        }


        return <div style={frameStyle}>
            <svg version="1.1" viewBox="0 0 400 400">
                <defs>
                    <filter id={this.filter0Id} x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceAlpha" dx="10" dy="10" />
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="5" />
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
                    </filter>
                </defs>
                <path filter={`url(#${this.filter0Id})`} fill='rgba(255,255,255,0.3)' stroke='none' id={this.p3Id}/>
                <path fill='#c79735' stroke='none'  id={this.p0Id}/>
                <path strokeWidth='0.5' fill='#c79735' stroke='#eeeeee' id={this.p1Id}/>

            </svg>
            <div className='rxxcl-text-zone-top'>
                <div className='rxxcl-text'>{this.props.text}</div>
            </div>


        </div>
    }
}

export default Hg4Rxxcl;