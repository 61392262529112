class Animate {

    startTime: number = 0;
    callBack: Function | undefined = undefined;
    duration: number = 0;
    endCallBack: Function | undefined = undefined;

    constructor(duration: number, callBack: Function, endCallBack: Function | undefined = undefined) {
        try {
            this.callBack = callBack;
            this.duration = duration;
            this.endCallBack = endCallBack;

        } catch (e) {
            console.log(e)
        }
    }


    start = () => {
        try {
            if (!this.detectIE() && window.requestAnimationFrame) {
                this.startTime = new Date().getTime();
                window.requestAnimationFrame(this.animateFrame)
            } else {//如果不支持动画则直接发送动画完毕
                if (this.callBack) {
                    this.callBack(1);
                }
                if (this.endCallBack) {
                    this.endCallBack();
                }
            }


        } catch (e) {
            console.log(e)
        }

    }

    animateFrame = () => {
        try {

            let currentTime = new Date().getTime();
            let lifePst: number = (currentTime - this.startTime) / this.duration;
            if (lifePst > 1) {
                lifePst = 1;
            }
            if (this.callBack) {
                let mPst = Math.sin((Math.PI / 1.8) * lifePst)
                this.callBack(mPst)
            }

            if (lifePst < 1) {
                window.requestAnimationFrame(this.animateFrame)
            } else {
                if (this.endCallBack) {
                    this.endCallBack();
                }
            }
        } catch (e) {
            console.log(e)
        }

    }

    detectIE = () => {
        let ua = window.navigator.userAgent;

        // Test values; Uncomment to check result …

        // IE 10
        // ua = 'Mozilla/5.0 (compatible; MSIE 10.0; Windows NT 6.2; Trident/6.0)';

        // IE 11
        // ua = 'Mozilla/5.0 (Windows NT 6.3; Trident/7.0; rv:11.0) like Gecko';

        // Edge 12 (Spartan)
        // ua = 'Mozilla/5.0 (Windows NT 10.0; WOW64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/39.0.2171.71 Safari/537.36 Edge/12.0';

        // Edge 13
        // ua = 'Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/46.0.2486.0 Safari/537.36 Edge/13.10586';

        let msie = ua.indexOf('MSIE ');
        if (msie > 0) {
            // IE 10 or older => return version number
            return parseInt(ua.substring(msie + 5, ua.indexOf('.', msie)), 10);
        }

        let trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            let rv = ua.indexOf('rv:');
            return parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }

        let edge = ua.indexOf('Edge/');
        if (edge > 0) {
            // Edge (IE 12+) => return version number
            return parseInt(ua.substring(edge + 5, ua.indexOf('.', edge)), 10);
        }

        // other browser
        return false;
    }


}


export default Animate;