import React from 'react'
import './Dashiji_png.scss'
import SequenceAnimateGroup from "../animate/SequenceAnimateGroup";
import Animate from "../animate/Animate";
import {ctx} from "../common/Ctx";


class Dashiji_png extends React.Component<any, any> {




    componentDidMount(): void {


    }

    render() {

        return <div className='dashiji_png'>

            {
                ctx.lang === 'cn'? <img className='dashiji_img' src="/time_line/zw_da.png"/>: <img className='dashiji_img' src="/time_line/hw_da.png"/>


            }



        </div>
    }
}

export default Dashiji_png;