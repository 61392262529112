class Context {
    list = []
    lang = 'cn'

    map = undefined
    isLogin = false;

    s = (id) => {
        try {
            if (!this.map) {
                this.initMap();
            }
            return this.map[id][this.lang]
        } catch (e) {
            console.error(id, e)
        }
        return ''
    }

    initMap = () => {
        this.map = {}
        this.list.forEach(l => {
            this.map[l.id] = l
        })
    }
}

let ctx = new Context();
export {ctx}