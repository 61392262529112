import React from 'react'
import {uuid} from "../common/UUID";
import './Hg3.scss'
import {hexagonManager} from "../common/HexagonManager";
interface Hg3GsysProps {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
}
class Hg3Gsys extends React.Component<Hg3GsysProps, any> {


    lgId: string = uuid.create();
    p0Id: string = uuid.create();
    p1Id: string = uuid.create();
    p3Id: string = uuid.create();
    p4Id: string = uuid.create();

    filterId: string = uuid.create();

    componentDidMount(): void {
        hexagonManager.draw(this.p3Id, 180, 200, 200);
        hexagonManager.draw(this.p0Id, 180, 200, 200);
        hexagonManager.draw(this.p1Id, 160, 200, 200);
    }

    componentWillUnmount(): void {

    }


    render() {


        let textStyle: React.CSSProperties = {
            lineHeight: `${this.props.lineHeight}px`,
            fontSize: `${this.props.fontSize}px`,

        }

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',
            zIndex:99

        }


        return <div style={frameStyle}>
            <svg version="1.1" viewBox="0 0 400 400">
                <defs>
                    <filter id="f1" x="-500" y="=-500" width="1000" height="1000">
                        <feOffset result="offOut" in="SourceGraphic" dx="6" dy="6"/>
                        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10"/>
                        <feBlend in="SourceGraphic" in2="blurOut" mode="normal"/>
                    </filter>
                </defs>
                <path filter="url(#f1)" fill='rgba(0,0,0,0.6)' stroke='none' id={this.p3Id} d=""/>
                <path fill='#ffffff' stroke='#c79735' id={this.p0Id} d=""/>
                <path strokeWidth='1' fill='#ffffff' stroke='#c79735' id={this.p1Id} d=""/>
            </svg>
            <div className='text-zone' style={textStyle}>
                {
                    this.props.text
                }
            </div>

        </div>
    }
}

export default Hg3Gsys;