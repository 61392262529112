import React from 'react'
import {uuid} from "../common/UUID";
import Animate from "../animate/Animate";
import {hexagonManager} from "../common/HexagonManager";
interface Hg2Props {
    top: number;
    left: number;
    width: number;
    height: number;
    pic: string;
    logo: string;
    rotate?: number;
    invokerY?: number;
    lineHeight?: number;
    fontSize?: number;
    text?: string;
}
class Hg2 extends React.Component<Hg2Props, any>  {


    lgId: string = uuid.create();
    p0Id:string = uuid.create();

    invokered: boolean = false;

    componentDidMount() {
        document.addEventListener('scroll', this.invoke)
    }

    componentWillUnmount() {
        document.removeEventListener('scroll', this.invoke)
    }

    invoke = () => {
        let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        if (!this.invokered && this.props.invokerY && scrollTop > this.props.invokerY) {
            this.invokered = true;
            new Animate(1000, (pst: number) => {
                hexagonManager.draw(this.p0Id, 180 * pst, 200, 200);
            }).start();
        }
    }


    render() {

        let frameStyle: React.CSSProperties = {
            top: this.props.top,
            left: this.props.left,
            width: this.props.width,
            height: this.props.height,
            position: 'absolute',

        }


        return <div style={frameStyle}>
            <svg id="svg1" version="1.1" viewBox="0 0 400 400">
                <defs>
                    <linearGradient gradientUnits="userSpaceOnUse" id={this.lgId} x1="0" y1="0" x2="400" y2="400">
                        <stop offset="0%" stopColor='red' opacity='0.1'/>
                        <stop offset="50%" stopColor='#c79735' opacity='0.5'/>
                        <stop offset="100%" stopColor='#c79735' opacity='1'/>
                        <animateTransform
                            attributeName="gradientTransform"
                            begin="0s"
                            dur="30s"
                            type="rotate"
                            from="0 200 200"
                            to="360 200 200"
                            repeatCount="indefinite"
                        />
                    </linearGradient>
                </defs>

                <path fill={`url(#${this.lgId})`} id={this.p0Id} />

            </svg>
        </div>
    }
}

export default Hg2;